<template>
    <div class="reports mt-5 w-75 mx-auto">
        <data-table
            v-if="!loading"
            id="dt"
            :value="reports"
            class="p-datatable-gridlines"
            selectionMode="single"
            dataKey="id"
            :paginator="true"
            :rows="5"
            @row-select="onRowSelect">
              <template #header>
                <div class="d-flex mb-3">
                  <h5 class="ml-3 my-4">My Reports</h5>
                </div>
              </template>
              <column class="wrap-bw" field="description" header="Description"></column>
              <column class="wrap-bw" field="created_at" header="Date Uploaded">
                  <template #body="slotProps">
                     <p>
                         {{ getReadableDate(slotProps.data.created_at)}}
                     </p>
                  </template>
              </column>
              <template #empty>
                  No reports found.
              </template>

              <template #footer>
                  Select a report to download a PDF copy.
              </template>
        </data-table>
        <div class="w-100 d-flex" v-else>
            <h4 class="mx-auto">
               Loading the files from the server  <i class='fa fa-circle-o-notch fa-spin'></i>
            </h4>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  setup() {
    const store = useStore();
    return {
      loading: computed(() => store.state.files.filesLoading),
      reports: computed(() => store.state.files.reports),
      loadReports: () => store.dispatch('files/loadReports'),
      launchReport: (id) => store.dispatch('files/launchReport', id),
    };
  },
  async mounted() {
    await this.loadReports();
  },
  methods: {
    getReadableDate(date) {
      return moment(date).format('DD/MM/YY');
    },
    onRowSelect(event) {
      this.launchReport(event.data.id);
    },
  },
};
</script>
