import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();

const devices = {
  namespaced: true,
  state: {
    devices: [],
    selectedDevice: null,
    devicesDataLoading: false,
    latestTelemetry: null,
  },
  mutations: {
    setDevices(state, deviceSelection) {
      state.devices = deviceSelection;
      if (state.selectedDevice === null) {
        // If no device is selected select the first one by default
        [state.selectedDevice] = deviceSelection;
      } else {
        // If a device is selected update it with the latest device data(for possible alarm changes)
        state.selectedDevice = deviceSelection.find((device) => device.uuid === state.selectedDevice.uuid);
      }
    },
    setSelectedDevice(state, selectedDevice) {
      state.selectedDevice = selectedDevice;
      state.latestTelemetry = null;
    },
    setDevicesDataLoading(state, loading) {
      state.devicesDataLoading = loading;
    },
    setLatestTelemetry(state, telemetry) {
      state.latestTelemetry = telemetry;
    },
  },
  actions: {
    async loadDevices({ commit }) {
      try {
        commit('setDevicesDataLoading', true);
        const response = await axios.get('devices');
        await commit('setDevices', response.data);
        commit('setDevicesDataLoading', false);
      } catch (error) {
        console.error(error);
        toast.error('There has been an error while getting the data. Please try again!');
        commit('setDevicesDataLoading', false);
      }
    },
    async updateSensorLocationsRegions({ commit }, formData) {
      try {
        commit('setDevicesDataLoading', true);
        await axios.post('/set-sensor-locations', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        toast.success('Sensor locations and regions updated');
        commit('setDevicesDataLoading', false);
      } catch (error) {
        console.error(error);
        toast.error('There has been an error while getting the data. Please try again!');
        commit('setDevicesDataLoading', false);
      }
    },
    async getBoxPlots({ commit }, { config }) {
      try {
        commit('setDevicesDataLoading', true);
        const response = await axios.get('getBoxPlots', config);
        commit('setDevicesDataLoading', false);
        return response.data;
      } catch (error) {
        toast.error('There has been an error while getting the boxplots. Please try again!');
        commit('setDevicesDataLoading', false);
      }
      return null;
    },
    async getHeatMap({ commit }, { config }) {
      try {
        commit('setDevicesDataLoading', true);
        const response = await axios.get('getHeatMap', config);
        commit('setDevicesDataLoading', false);
        return response.data;
      } catch (error) {
        toast.error('There has been an error while getting the heatmap. Please try again!');
        commit('setDevicesDataLoading', false);
      }
      return null;
    },
    async retrieveLatestData({ commit, state }, { selectedDevice }) {
      let tempSelectedDevice = selectedDevice;
      if (tempSelectedDevice === null) {
        tempSelectedDevice = state.selectedDevice;
      }
      const config = {
        params: {
          deviceId: tempSelectedDevice.uuid,
        },
      };
      try {
        commit('setDevicesDataLoading', true);
        const response = await axios.get('getLatestTelemetry', config);
        commit('setLatestTelemetry', response.data);
        commit('setDevicesDataLoading', false);
        return response.data;
      } catch (error) {
        console.error(error);
        toast.error('There has been an error while getting data');
        commit('setDevicesDataLoading', false);
        return {
          tempDataSets: [],
          tempLabels: [],
        };
      }
    },
    async updateCameraEnabled({ commit }, formData) {
      try {
        commit('setDevicesDataLoading', true);
        await axios.post('/change-camera-enabled', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        toast.success('Gif creation successfully changed for device');
        commit('setDevicesDataLoading', false);
      } catch (error) {
        console.error(error);
        toast.error('There has been an error while submitting data. Please try again!');
        commit('setDevicesDataLoading', false);
      }
    },
    async retrieveDataForPeriod({ commit }, { config }) {
      try {
        commit('setDevicesDataLoading', true);
        const response = await axios
          .get('getTsData', config);
        commit('setLatestTelemetry', response.data);
        commit('setDevicesDataLoading', false);
        return response.data;
      } catch (error) {
        console.error(error);
        toast.error('There has been an error while getting the data. Please try again!');
        commit('setDevicesDataLoading', false);
        return {
          tempDataSets: [],
          tempLabels: [],
        };
      }
    },
  },
  getters: {},
};
export default devices;
