<template>
  <dropdown
      v-if="!loading"
      v-model="selectedCompany"
      :options="options"
      id="companySelector"
      :class="classProp"
      ref="company"
      @change="selectCompany($event)"
      optionLabel="name"
      placeholder="Select a company"
  />
  <p class="mt-2" v-else> Loading...  <i v-if="loading" class='fa fa-circle-o-notch fa-spin'></i></p>
</template>

<script>

export default {
  name: 'CompanySelector',
  props: {
    options: {
      type: Array,
    },
    classProp: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    initalCompany: {
      type: Object,
      default: null,
    },
  },
  emits: ['companyChanged'],
  data() {
    return {
      selectedCompany: null,
    };
  },
  mounted() {
    this.selectedCompany = this.initalCompany;
  },
  watch: {
    initalCompany(newVal) {
      this.selectedCompany = newVal;
    },
  },
  methods: {
    async selectCompany(company) {
      this.selectedCompany = company.value;
      this.$emit('companyChanged', company);
    },
  },
};
</script>
