<template>
    <div v-if="!loading" class="px-4 mt-5 bg-lgray">
        <div class="d-flex flex-sm-col-reverse flex-lg-row mr-4">
            <div class="col-12 col-lg-6 flex-grow-1 mr-4 mt-under-xl-3 px-0 d-flex bg-white-gray-text">
                <data-table
                        v-if="!loading"
                        :value="notifications"
                        class="p-datatable-lg my-auto"
                        ref="dt"
                        id="dt"
                        :paginator="true"
                        :rows="5"
                        columns="">
                    <template #header class="">
                        <div class="d-flex mb-3">
                            <h5 class="ml-3 my-4">Notification Feed</h5>
                            <pv-button
                                    icon="pi pi-external-link"
                                    label="Export"
                                    class="mw-100 ml-auto export-btn"
                                    @click="exportCSV($event)"
                            />
                        </div>
                    </template>
                    <column field="type" header="Type"></column>
                    <column field="created_at" class="w-25" header="Time">
                        <template #body="slotProps">
                            {{ getReadableDate(slotProps.data.created_at)}}
                        </template>
                    </column>
                    <column field="description" header="Notes"></column>
                    <template #empty>
                        No notifications found.
                    </template>
                </data-table>
            </div>
            <div class="col-12 col-lg-6 m-0 flex-grow-1 d-flex flex-column bg-white-gray-text">
                 <div class="d-flex d-row">
                   <h5 class="text-left p-header w-50">Overview of alarms</h5>
                   <device-selector
                    @device-changed="selectDevice($event)"
                    :loading="loading"
                    :options="devices"
                    :initial-device="selectedDevice"
                    :classProp="'ml-auto my-2 device-selector selector-my-asset'" >
                  </device-selector>
                 </div>
                <div class="d-flex h-100 w-100">
                    <div class="mx-auto my-auto h-100 w-100" v-if="selectedDevice && selectedDevice.alarms.length > 0">
                        <chart
                                v-if="chartUpdated"
                                type="pie"
                                class="h-100 w-100"
                                :options="options"
                                id="myAsset-chart"
                                :data="series"
                        >
                        </chart>
                    </div>
                    <div class="h-100 w-100 d-flex" v-else-if="!selectedDevice">
                      <p class="m-auto">
                        Select a device to see it's alarm breakdown here
                      </p>
                    </div>
                    <div class="h-100 w-100 d-flex" v-else>
                      <p class="m-auto">
                        No alarms have been raised on this device
                      </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h4 class="mt-5" v-else>
        Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
    </h4>
</template>

<script>
import { mapState, useStore } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { computed } from 'vue';
import DeviceSelector from '../components/DeviceSelector.vue';

export default {
  setup() {
    const store = useStore();
    return {
      loadDevices: () => store.dispatch('devices/loadDevices'),
      retrieveLatestData: (selectedDevice) => store.dispatch(
        'devices/retrieveLatestData',
        {
          selectedDevice,
        },
      ),
      mapAlarms: (alarmsToMap) => store.dispatch(
        'alarms/mapAlarms',
        {
          alarmsToMap,
        },
      ),
      retrieveDataForPeriod: (config) => store.dispatch(
        'devices/retrieveDataForPeriod', {
          config,
        },
      ),
      selectDeviceInStore: (device) => store.commit('devices/setSelectedDevice', device),
      selectedDevice: computed(() => store.state.devices.selectedDevice),
      alarms: computed(() => (store.state.devices.selectedDevice ? store.state.devices.selectedDevice.alarms : '')),
      devices: computed(() => store.state.devices.devices),
    };
  },
  data() {
    return {
      chartUpdated: false,
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
        },
      },
      series: {
        labels: ['Cleared', 'In Progress'],
        datasets: [
          {
            data: [],
            backgroundColor: ['#ececec', '#bbbbbb', '#595959'],
            hoverBackgroundColor: ['#e2e2e2', '#b1b1b1', '#D0D0D0'],
            borderWidth: 0,
          },
        ],
      },
      notifications: [
      ],
    };
  },
  computed: {
    ...mapState('alarms', ['alarms']),
  },
  methods: {
    async selectDevice(device) {
      this.loading = true;
      await this.selectDeviceInStore(device);
      await this.updateChart();
      this.loading = false;
    },
    getReadableDate(date) {
      return moment(date).format('DD/MM/YY H:mm');
    },
    updateChart() {
      const pieData = [0, 0];
      Object.entries(this.alarms).map(([, value]) => {
        if (value.status === 'ACTIVE_UNACK' || value.status === 'CLEARED_UNACK' || value.status === 'ACTIVE_ACK') {
          pieData[1] += 1;
        } else if (value.status === 'CLEARED_ACK') {
          pieData[0] += 1;
        }
      });
      this.series.datasets[0].data = pieData;
      this.chartUpdated = true;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  async mounted() {
    this.loading = true;
    await this.loadDevices();
    if (this.selectedDevice) {
      this.updateChart();
    }
    await axios.get('/notifications').then((response) => (this.notifications = response.data)).catch((error) => console.log(error.response));
    this.loading = false;
  },
  components: {
    DeviceSelector,
  },
};
</script>
