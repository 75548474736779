/* eslint-disable import/no-cycle */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import alarms from './modules/alarms';
import devices from './modules/devices';
import files from './modules/files';
import users from './modules/users';
import helpArticles from './modules/help-articles';

export default createStore({
  modules: {
    alarms,
    devices,
    files,
    users,
    helpArticles,
  },
  plugins: [createPersistedState()],
});
