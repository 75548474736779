<template>
  <div v-if="!loading">
    <div class="d-flex  flex-sm-col-reverse flex-lg-row mx-0 py-4 justify-content-space-evenly">
      <div class="col-10 px-0 flex-grow-1">
        <div class="d-flex flex-column bg-white-gray-text px-4 pb-3 h-100">
          <h5 class="text-black mr-auto my-4">Tunnel scatter chart</h5>
          <div class="row m-0 mb-2">
              <div class="col-12 align-text-left">
                <label class="my-auto mr-2" for="fromDate">Start date</label>
                <calendar id="fromDate" :class="toDate && fromDate > toDate ? 'p-invalid' : ''"
                          v-model="fromDate"
                          :showTime="true"
                          :show-seconds="true"
                          :hide-on-date-time-select="true"
                          :stepSecond="5"
                          dateFormat="dd/mm/yy"
                />
              </div>
              <div class="col-12 align-text-left my-2">
                <label class="my-auto mr-3" for="toDate">End date</label>
                <calendar id="toDate" class="mr-2"
                          :class="fromDate && fromDate > toDate ? 'p-invalid' : ''"
                          v-model="toDate"
                          :showTime="true"
                          :show-seconds="true"
                          :hide-on-date-time-select="true"
                          :stepSecond="5"
                          dateFormat="dd/mm/yy"
                />
              </div>
              <div class="col-12 align-text-left my-2">
                <label class="my-auto mr-3" for="dpLimit">Datapoints limit</label>
                <input type="number" id="dpLimit" class="mr-2"
                          min="0"
                          max="1000"
                          v-model="dataPointsLimit"
                />
              </div>
              <div
                  v-if="selectedDevice && fromDate && toDate"
                  class="border w-100 mx-3 mb-4 pl-2"
              >
                <div class="text-left mt-2">
                  <pv-button
                      @click="loadImageData()"
                      class="p-button-rounded p-button-text p-button-plain"
                      :icon="dataLoading ? 'fa fa-circle-o-notch fa-spin' : 'pi pi-plus'"
                      label="Add a scatter chart"
                      :disabled="dataLoading || fromDate > toDate"
                  />
                </div>
              </div>
              <div class="ml-auto">
                <pv-button
                    v-if="!dataLoading && telemetry"
                    @click="exportToPng()"
                    class="btn btn-black"
                    icon="pi pi-refresh"
                    label="Export to PNG"
                />
              </div>
            </div>
          <div>
          <div class="sensor-chart mt-2" v-if="selectedDevice && !chartLoading && telemetry">
            <div v-if="telemetry">
              <div ref="telemetryChart">
                <tunnel-chart class="pt-2" :telemetry="telemetry" :alarm-selected="false" :selected-device="selectedDevice"></tunnel-chart>
              </div>
            </div>
          </div>
          <div v-else-if="chartLoading" class="d-flex flex-column bg-white-gray-text px-4 h-100">
            <h4 class="my-2">
              Loading <i class='fa fa-circle-o-notch fa-spin'></i>
            </h4>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="mt-5" v-else>
    Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
  </h4>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
/* eslint-disable import/no-cycle */
import moment from 'moment';
import router from '../router';
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import TunnelChart from '../components/TunnelChart';

export default {
  components: {
    TunnelChart,
  },
  setup() {
    const chart = ref(null);
    const store = useStore();

    return {
      userRole: computed(() => store.state.users.userRole),
      loadDevices: () => store.dispatch('devices/loadDevices'),
      devices: computed(() => store.state.devices.devices),
      retrieveDataForPeriod: (config) => store.dispatch(
        'devices/retrieveDataForPeriod', {
          config,
        },
      ),
      selectDeviceInStore: (device) => store.commit('devices/setSelectedDevice', device),
      selectedDevice: computed(() => store.state.devices.selectedDevice),
      dataLoading: computed(() => store.state.devices.devicesDataLoading),
      loadCompanies: () => store.dispatch('users/getCompanies'),
      companies: computed(() => store.state.users.companies),
      chart,
    };
  },
  data() {
    return {
      loading: true,
      chartLoading: false,
      telemetry: null,
      output: null,
      fromDate: 'Mon Aug 07 2023 15:40:55',
      toDate: 'Tue Nov 21 2023 15:40:55',
      dataPointsLimit: 15,
    };
  },
  async mounted() {
    if (this.userRole !== 'admin') {
      router.push({ name: 'Home' });
    }
    this.loading = true;
    await this.loadDevices();
    const deviceFound = this.devices.filter((device) => device.uuid === 'c6a0fe30-892c-11ee-b062-27e0facd3ca8');
    if (deviceFound.length > 0) {
      this.selectDeviceInStore(deviceFound[0]);
    }
    this.loading = false;
  },
  methods: {
    async print() {
      const options = {
        type: 'dataURL',
      };
      this.output = await this.$html2canvas(this.$refs.telemetryChart, options);
    },
    async exportToPng() {
      await this.print();
      const fileLinkTemp = document.createElement('a');
      const fileName = `${this.selectedDevice.name}-telemetry-${moment(this.fromDate).format('D_MMM_YY')
      }-${
        moment(this.toDate).format('D_MMM_YY')}.png`;
      fileLinkTemp.href = this.output;
      fileLinkTemp.setAttribute('download', fileName);
      document.body.appendChild(fileLinkTemp);
      fileLinkTemp.click();
    },
    async loadImageData() {
      this.selectedDevice.lastActive = null;
      const config = {
        params: {
          ts: moment(this.toDate).format('x'),
          deviceId: this.selectedDevice.uuid,
          lowerTs: moment(this.fromDate).format('x'),
          keys: null,
          limit: this.dataPointsLimit,
        },
      };
      this.chartLoading = true;
      const tempTelemetry = await this.retrieveDataForPeriod(config);
      if (Object.keys(tempTelemetry).length === 0) {
        this.telemetry = null;
        this.selectedDevice.lastActive = moment(tempTelemetry).format('DD/MM/Y HH:mm');
      } else if (Object.keys(tempTelemetry).length > 0 && !tempTelemetry.errorCode) {
        // Sensors come in with one for Height one for Width so divide total number by 2
        const countOfSensors = Object.keys(tempTelemetry).length / 2;
        const newTelemetry = [];
        for (let i = 1; i < countOfSensors; i++) {
          for (let x = 0; x < tempTelemetry[`Height ${i}`].length; x++) {
            let timeStamp = tempTelemetry[`Height ${i}`][x].ts;
            timeStamp = moment(timeStamp).format('DD/MM/YY HH:mm');
            if (newTelemetry[timeStamp] && newTelemetry[timeStamp].length > 0) {
              newTelemetry[timeStamp].push({
                x: tempTelemetry[`Width ${i}`][x].value,
                y: tempTelemetry[`Height ${i}`][x].value,
              });
            } else {
              newTelemetry[timeStamp] = [];
              newTelemetry[timeStamp].push({
                x: tempTelemetry[`Width ${i}`][x].value,
                y: tempTelemetry[`Height ${i}`][x].value,
              });
            }
          }
        }
        this.telemetry = newTelemetry;
      } else {
        this.selectedDevice.error = true;
      }
      if (this.$refs.chart) {
        this.$refs.chart.refresh();
      }
      this.chartLoading = false;
    },
  },
};
</script>
