<template>
  <div class="home-page d-flex" :style="{ background: `url(${require('@/assets/guernsey-1920.jpg')}) no-repeat center`,}">
    <div class="my-auto mx-auto text-black-white-bg-shadow w-md-40 py-5">
      <h1 v-if="currentHr < 12">Good morning</h1>
      <h1 v-else-if="currentHr < 18">Good afternoon</h1>
      <h1 v-else>Good evening</h1>
      <p class="">
        Welcome to your Monolith asset monitoring dashboard
      </p>
      <p class="mx-auto w-75">
        For a more in-depth analysis and access to your devices log into
        <a :href="thingsboardUrl" target="_blank" rel="noopener">Thingsboard</a>
      </p>
      <pv-button @click="$router.push('overview')" class="btn btn-black mt-2">
        OVERVIEW
        <i class='fa fa-arrow-right'></i>
      </pv-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      thingsboardUrl: process.env.VUE_APP_THINGSBOARD_URL
        ? process.env.VUE_APP_THINGSBOARD_URL
        : 'https://monolith.openindustry.in/',
      currentHr: (new Date()).getHours(),
    };
  },
};
</script>
