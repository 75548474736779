<template>
  <label class="text-muted" for="alarm">Select alarm</label>
  <dropdown
      v-model="selectedAlarm"
      :options="options"
      id="alarm"
      class="w-100"
      ref="selectedAlarm"
      panelClass="ml-2"
      @change="dropDownChangeAlarm($event)"
      optionLabel="timestamp"
      placeholder="Select an alarm">
    <template #value="slotProps">
      <div class="p-dropdown-value" v-if="slotProps.value">
        <span>{{ getReadableDate(slotProps.value) }}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
    <template #option="slotProps">
      <div class="p-dropdown-option admin-options">
        <span>{{ getReadableDate(slotProps.option) }}</span>
      </div>
    </template>
  </dropdown>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AlarmManagemeentSelector',
  props: {
    options: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    initialAlarm: {
      type: Object,
      default: null,
    },
  },
  emits: ['alarmChanged'],
  data() {
    return {
      selectedAlarm: null,
    };
  },
  mounted() {
    this.selectedAlarm = this.initialAlarm;
  },
  watch: {
    initialAlarm(newVal) {
      this.selectedAlarm = newVal;
    },
  },
  methods: {
    async dropDownChangeAlarm(alarm) {
      this.selectedAlarm = alarm.value;
      this.$emit('alarmChanged', alarm);
    },
    getReadableDate(value) {
      if (value.timestamp) {
        return moment(value.timestamp).format('DD/MM/YY HH:mm');
      }
      return moment(value).format('DD/MM/YY HH:mm');
    },
  },
};
</script>
