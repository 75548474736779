<template>
  <div class="d-flex">
    <div class="w-100 m-0 px-4 py-4 upload-wrapper text-left">
      <div class="large-12 medium-12 small-12 cell mb-2 ml-4" v-if="selectedDevice && selectedDevice.alarms.length > 0">
        <h4 class="mt-1">Change alarm status</h4>
        <div class="row">
          <div class="col-6 input-group ml-0 mt-3 mb-2">
            <div class="w-100">
              <alarm-management-selector
              :options="selectedDevice.alarms"
              :initialAlarm="selectedAlarm"
              @alarm-changed="dropDownChangeAlarm($event)">
              </alarm-management-selector>
            </div>
            <div class="w-100">
              <label class="text-muted w-100 mt-2" for="cameraEnabled">Display device gifs</label>
              <input-switch
                  v-model="selectedDevice.camera_enabled"
                  class="my-auto" id="cameraEnabled"
                  @click="disableCameraForDevice()"
                  v-tooltip="'Turns gif creation and display on/off for the device'"
              />
            </div>
            <div class="w-100">
              <p class="text-muted mb-0"> Select status</p>
              <div class="row m-0 mt-1">
                <div class="ml-0 mb-2 mr-2 d-flex">
                  <radio-button name="status"
                                id="status1"
                                class="my-auto"
                                :value="alarmStatuses[0].status"
                                v-model="selectedStatus"
                                :disabled="(selectedAlarm?.status === 'CLEARED_ACK')"/>
                  <label class="ml-1 my-auto" for="status1">
                    {{ alarmStatuses[0].label }}
                  </label>
                </div>
                <div class="ml-0 mb-2 d-flex">
                  <radio-button id="status2"
                                class="my-auto"
                                name="status"
                                :value="alarmStatuses[1].status"
                                v-model="selectedStatus"/>
                  <label class="ml-1 my-auto" for="status2" > {{ alarmStatuses[1].label }} </label>
                </div>
              </div>
            </div>
            <div class="w-100">
              <label class="text-muted w-100" for="note">Alarm note</label>
              <textarea
                  rows="4"
                  v-model="selectedAlarm.note"
                  class="w-100"
                  placeholder="Enter alarm note here"
                  ref="note"
                  id="note"
                  aria-label="Note"
                  maxlength="250">
              </textarea>
              <p><small> {{ selectedAlarm.note?.length }}/250 </small></p>
            </div>
          </div>
          <div class="w-50 d-flex" v-if="selectedDevice.camera_enabled">
            <img class="mx-auto mw-100" :src="selectedAlarm.gif_url" alt="">
          </div>
        </div>

        <pv-button class="btn btn-black" @click="changeAlarmStatus()" :disabled="loading">
          SUBMIT  <i v-if="loading" class='fa fa-circle-o-notch fa-spin'></i>
        </pv-button>

      </div>
      <h4 v-else class="mt-1 ml-3">There are no alarms for the selected device</h4>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { useStore } from 'vuex';
import AlarmManagementSelector from './AlarmManagementSelector.vue';

export default {
  name: 'AlarmManagemeentSelector',
  props: {
    options: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    initialDevice: {
      type: Object,
      default: null,
    },
    initialAlarm: {
      type: Object,
      default: null,
    },
  },
  components: { AlarmManagementSelector },
  emits: ['alarmChanged'],
  data() {
    return {
      selectedAlarm: null,
      selectedDevice: null,
      selectedStatus: null,
      alarmStatuses: [
        { status: 'ACTIVE_ACK', label: 'In Progress / Acknowledged' },
        { status: 'CLEARED_ACK', label: 'Cleared' },
      ],
    };
  },
  mounted() {
    this.selectedDevice = this.initialDevice;
    this.selectedAlarm = this.initialAlarm;
  },
  watch: {
    selectedAlarm(newVal) {
      if (newVal) {
        if (newVal.status !== 'CLEARED_ACK' && newVal.status !== 'ACTIVE_ACK') {
          this.selectedStatus = 'CLEARED_UNACK';
        } else {
          this.selectedStatus = newVal.status;
        }
      }
    },
    initialAlarm(newVal) {
      this.selectedAlarm = newVal;
    },
    initialDevice(newVal) {
      this.selectedDevice = newVal;
    },
  },
  setup() {
    const store = useStore();

    return {
      updateAlarmStatus: (formData) => store.dispatch('alarms/updateAlarmStatus', formData),
      updateCameraEnabled: (formData) => store.dispatch('devices/updateCameraEnabled', formData),
    };
  },
  methods: {
    dropDownChangeAlarm($event) {
      this.selectedAlarm = $event.value;
      if (this.selectedAlarm?.status !== 'CLEARED_ACK') {
        this.selectedStatus = 'CLEARED_UNACK';
      } else {
        this.selectedStatus = this.selectedAlarm.status;
      }
    },
    getReadableDate(value) {
      if (value.timestamp) {
        return moment(value.timestamp).format('DD/MM/YY HH:mm');
      }
      return moment(value).format('DD/MM/YY HH:mm');
    },
    async changeAlarmStatus() {
      const formData = new FormData();
      formData.append('alarm_id', this.selectedAlarm.uuid);
      formData.append('status', this.selectedStatus);
      formData.append('note', document.getElementById('note').value);
      await this.updateAlarmStatus(formData);
      this.$emit('alarmChanged');
    },
    async disableCameraForDevice() {
      const formData = new FormData();
      formData.append('cameraEnabled', !this.selectedDevice.camera_enabled);
      formData.append('deviceId', this.selectedDevice.id);
      await this.updateCameraEnabled(formData);
      this.$emit('alarmChanged');
    },
  },
};
</script>
