<template>
  <div class="vtkContainerOuter bg-white">
    <h5 class="text-black text-left ml-4 pt-4 mt-4">Your structure</h5>
    <p class="text-muted small text-left ml-4">You can see a 3D model of your structure in this box.
      The spheres around the sensors represent the telemetry(the bigger the sphere the closer the telemetry is to the alarm thresholds for said sensor)
      Use the dropdown menu to manipulate the display, view direction and to see individual sensors.</p>
    <div ref="vtkContainer" />
    <table class="controls" v-if="sensors">
      <tbody>
      <tr>
        <td v-if="!isFullSensorChanged && !isSpheresCheckboxChanged && !isSensorChanged && !isRepresentationChanged && !isViewAngleChanged && !loading">
          <select
              class="w-100"
              :value="representation"
              @change="setRepresentation($event.target.value)"
          >
            <option value="0">Points</option>
            <option value="1">Wireframe</option>
            <option value="2">Surface</option>
          </select>
          <select
              class="w-100"
              :value="sensor"
              :disabled = "!!fullSensorChecked || sensorSelectorDisabled"
              @change="setSensor($event.target.value)"
          >
            <option v-for="(item, key) in sensors" :value="key" :key="key">{{ item.name }}</option>
          </select>
          <select
              class="w-100"
              :value="position"
              id="positionSelector"
              @change="setViewDirection($event.target)"
          >
            <option value="1848.37,3744.52,2678.61" data-viewUp="0.001,0.0124,1">Default</option>
            <option value="6923.18,-5814.42,2803.9" data-viewUp="0,0,1">Left</option>
            <option value="-16160,-5814.2,2803.9" data-viewUp="0,0,1">Right</option>
            <option value="-4618.41,5717.27,2803.9" data-viewUp="0,0,1">Front</option>
            <option value="-4618.41,-17356,2803.9" data-viewUp="0,0,1">Back</option>
            <option value="-4618.41,-5814.42,-8737.68" data-viewUp="0,1,0">Bottom</option>
            <option value="-4618.41,-5814.42,14345.5" data-viewUp="0,1,0">Top</option>
          </select>

          <div class="row">
            <div class="col-6 align-text-left mt-1 pr-0">
              <input class="mr-1" v-model="spheresChecked" @change="setSpheresChecked($event.target.checked)" type="checkbox" id="checkboxSpheres" >
              <label for="checkboxSpheres">Sensor spheres</label>
              <div class="">
                <input :disabled="fullSensorCbDisabled" class="mr-1" v-model="fullSensorChecked" @change="setFullSensorChecked($event.target.checked)" type="checkbox" id="checkbox" >
                <label for="checkbox">Full sensor view</label>
              </div>
            </div>

            <div class="col-6 d-flex my-auto">
              <button class="btn btn-dark mt-1 mr-1 ml-auto" @click="resetView()">
                Reset
              </button>
            </div>

          </div>
        </td>
        <td v-else>
          <h4 class="my-auto">
            Loading <i class='fa fa-circle-o-notch fa-spin'></i>
          </h4>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-tooltip="'The heatmap shows the spread of the strain. Red represents the point where there is maximum strain faced by the sensor in that location and blue the minimum'" class="colorLegend d-flex" >
      <h5 class="mr-1">min</h5>
      <svg height="50" width="300">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:rgb(118,118,255);stop-opacity:1" />
            <stop offset="25%" style="stop-color:rgb(112,255,217);stop-opacity:1" />
            <stop offset="50%" style="stop-color:rgb(141,255,112);stop-opacity:1" />
            <stop offset="75%" style="stop-color:rgb(255,209,109);stop-opacity:1" />
            <stop offset="95%" style="stop-color:rgb(255,109,109);stop-opacity:1" />
          </linearGradient>
        </defs>
        <rect width="300" height="25" style="fill:url(#grad1)" />
        Sorry, your browser does not support inline SVG.
      </svg>
      <h5 class="ml-1">max</h5>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable import/no-cycle */
import {
  onBeforeUnmount, onMounted, ref, unref, watchEffect,
} from 'vue';
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import { mapState, useStore } from 'vuex';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkXMLPolyDataReader from '@kitware/vtk.js/IO/XML/XMLPolyDataReader';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkCalculator from '@kitware/vtk.js/Filters/General/Calculator';
import vtkDataSet from '@kitware/vtk.js/Common/DataModel/DataSet';
import vtkWarpScalar from '@kitware/vtk.js/Filters/General/WarpScalar';
import vtkOrientationMarkerWidget from '@kitware/vtk.js/Interaction/Widgets/OrientationMarkerWidget';
import vtkAnnotatedCubeActor from '@kitware/vtk.js/Rendering/Core/AnnotatedCubeActor';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkLabelWidget from '@kitware/vtk.js/Interaction/Widgets/LabelWidget';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkAppendPolyData from '@kitware/vtk.js/Filters/General/AppendPolyData';
import { useRoute } from 'vue-router';

const { FieldDataTypes } = vtkDataSet;
// let background = [1, 1, 1];
global.pipeline = {};
// REF: https://kitware.github.io/vtk-js/examples/GeometryViewer.html
export default {
  props: {
    devices: {
      required: true,
    },
  },

  data() {
    return {
    };
  },

  methods: {
  },

  setup() {
    if (useRoute().name === 'DataFeed') {
      const store = useStore();
      const vtkContainer = ref(null);
      const context = ref(null);
      const sensorVtk = ref(null);
      const representation = ref(2);
      const fullSensorChecked = ref(false);
      const sensorSelectorDisabled = ref(false);
      const spheresChecked = ref(true);
      const fullSensorCbDisabled = ref(false);
      const position = ref(null);
      const viewUp = ref(null);
      sensorVtk.value = [];
      const alarmVtk = ref(null);
      const sensor = ref(0);
      const sensors = ref(0);
      const isSensorChanged = ref(false);
      const isRepresentationChanged = ref(false);
      const isFullSensorChanged = ref(false);
      const isSpheresCheckboxChanged = ref(false);
      const isViewAngleChanged = ref(false);
      const loading = ref(false);
      const renderer = ref(null);
      const axesConst = ref(null);

      isFullSensorChanged.value = false;
      isSensorChanged.value = false;
      isRepresentationChanged.value = false;
      isViewAngleChanged.value = false;
      isSpheresCheckboxChanged.value = false;
      loading.value = true;
      alarmVtk.value = [];
      // Default values for viewing angle
      position.value = [1848.37, 3744.52, 2678.61];
      viewUp.value = [0.001, 0.0124, 1];

      const getCurrentDevice = function () {
        return store.state.devices.selectedDevice;
      };

      const getCurrentAlarm = function () {
        return store.state.alarms.currentAlarm;
      };

      const retrieveLatestData = async function (selectedDevice) {
        if (!selectedDevice.lastActive && store.state.devices.latestTelemetry !== null) {
          await store.dispatch(
            'devices/retrieveLatestData',
            {
              selectedDevice,
            },
          );
        }
      };

      const getLatestTelemetry = async function () {
        if (!store.state.devices.latestTelemetry) {
          await retrieveLatestData(getCurrentDevice());
        }
        let telemetry = store.state.devices.latestTelemetry;
        telemetry = Promise.resolve(telemetry).then((value) => value);
        if (telemetry) {
          return telemetry;
        }
        return null;
      };

      const getVtpUrl = async function (vtpId) {
        await store.dispatch('files/getVtpUrl', vtpId);
        return store.state.files.vtpUrl;
      };

      const setFullSensorChecked = function (checked) {
        isFullSensorChanged.value = true;
        fullSensorChecked.value = checked;
      };

      const setSensorSelectorDisabled = function (value) {
        sensorSelectorDisabled.value = value;
      };

      const setSpheresChecked = function (checked) {
        spheresChecked.value = checked;
        isSpheresCheckboxChanged.value = true;
      };

      const setRepresentation = function (rep) {
        isRepresentationChanged.value = true;
        representation.value = Number(rep);
      };

      const setSensor = function (sens) {
        isSensorChanged.value = true;
        sensor.value = Number(sens);
      };
      const simpleFilter = vtkCalculator.newInstance();

      const createPipeline = async function (fullScreenRenderer) {
        const renderer = fullScreenRenderer.getRenderer();
        const renderWindow = fullScreenRenderer.getRenderWindow();

        // VTK pipeline
        const vtpReader = vtkXMLPolyDataReader.newInstance();
        let index = getCurrentDevice().model.findIndex((item) => item.type === 'default');
        if (index === -1 || (getCurrentDevice().model.length > 1 && fullSensorChecked.value)) {
          setFullSensorChecked(true);
          if (index === -1) {
            fullSensorCbDisabled.value = true;
          }
          index = getCurrentDevice().model.findIndex((item) => item.type === 'full-sensor');
        }
        const vtpUrl = await getVtpUrl(getCurrentDevice().model[index].id);
        return vtpReader.setUrl(vtpUrl, { loadData: true }).then(() => {
          vtpReader.update();
          const lookupTable = vtkColorTransferFunction.newInstance();
          const warpScalar = vtkWarpScalar.newInstance();

          const mapper = vtkMapper.newInstance({
            interpolateScalarsBeforeMapping: true,
            colorMode: ColorMode.DEFAULT,
            scalarMode: ScalarMode.DEFAULT,
            useLookupTableScalarRange: true,
            lookupTable,
            scalarVisibility: true,
          });

          // simpleFilter.setInputConnection(planeSource.getOutputPort());
          simpleFilter.setInputConnection(vtpReader.getOutputPort(0));
          // simpleFilter.setInputData(source);
          simpleFilter.update();
          warpScalar.setInputConnection(simpleFilter.getOutputPort());
          const actor = vtkActor.newInstance();
          warpScalar.update();
          const source = warpScalar;

          function applyPreset() {
            const scalars = warpScalar.getOutputData().getPointData().getScalars();
            const dataRange = [].concat(scalars ? scalars.getRange() : [0, 0.01]);
            const preset = vtkColorMaps.getPresetByName('erdc_rainbow_bright');
            lookupTable.applyColorMap(preset);
            lookupTable.setMappingRange(dataRange[0], dataRange[1]);
            lookupTable.updateRange();
            mapper.setUseLookupTableScalarRange(lookupTable);
          }

          applyPreset();

          global.pipeline['3D'] = {
            actor,
            mapper,
            source,
            lookupTable,
            renderer,
            renderWindow,
          };

          actor.getProperty().setEdgeVisibility(false);
          actor.getProperty().setDiffuse(0.9);
          actor.getProperty().setSpecular(0.2);
          actor.getProperty().setSpecularPower(30);
          actor.getProperty().setSpecularColor(1.0, 1.0, 1.0);
          actor.getProperty().setAmbient(0.25);
          actor.setMapper(mapper);
          mapper.setInputConnection(source.getOutputPort());
          mapper.update();
          renderer.addActor(actor);
          renderer.getActiveCamera().setViewUp(0.001, 0.0124, 1);
          renderer.getActiveCamera().setPosition(1848.37, 3744.52, 2678.61);
          renderer.getActiveCamera().setFocalPoint(-4618.41, -5814.2, 2803.9);
          renderer.getActiveCamera().setViewAngle(30);

          // First render
          renderer.resetCamera();
          renderWindow.render();
          applyPreset();

          // create axes
          const axes = vtkAnnotatedCubeActor.newInstance();
          axes.setDefaultStyle({
            text: 'LEFT',
            fontStyle: 'bold',
            fontFamily: 'Arial',
            fontColor: 'black',
            fontSizeScale: (res) => res / 4,
            faceColor: '#668660',
            faceRotation: 90,
            edgeThickness: 0.1,
            edgeColor: 'black',
            resolution: 400,
          });
          axes.setXMinusFaceProperty({
            text: 'RIGHT',
            faceColor: '#dede95',
            faceRotation: -90,
          });
          axes.setYPlusFaceProperty({
            text: 'FRONT',
            faceColor: '#a2a2a2',
            faceRotation: 180,
          });
          axes.setYMinusFaceProperty({
            text: 'BACK',
            faceColor: '#6d9f9f',
            faceRotation: 0,
            fontColor: 'white',
          });
          axes.setZPlusFaceProperty({
            text: 'TOP',
            faceColor: '#7a6d9f',
            faceRotation: 180,
          });
          axes.setZMinusFaceProperty({
            text: 'BOT',
            faceColor: '#9f6d76',
            faceRotation: 0,
          });
          axesConst.value = axes;
          const orientationWidget = vtkOrientationMarkerWidget.newInstance({
            actor: axes,
            interactor: renderWindow.getInteractor(),
          });
          orientationWidget.setEnabled(true);
          orientationWidget.setViewportCorner(
            vtkOrientationMarkerWidget.Corners.TOP_RIGHT,
          );
          orientationWidget.setViewportSize(0.15);
          orientationWidget.setMinPixelSize(100);
          orientationWidget.setMaxPixelSize(300);
          const sphereSource = vtkSphereSource.newInstance();
          sphereSource.setRadius(0);
          const spheresArray = [];
          const tempLabelsArray = [];
          spheresArray.push(sphereSource);
          const labelWidget = vtkLabelWidget.newInstance();
          labelWidget.setInteractor(renderWindow.getInteractor());
          labelWidget.setEnabled(1);

          context.value = {
            fullScreenRenderer,
            applyPreset,
            simpleFilter,
            orientationWidget,
            renderWindow,
            renderer,
            actor,
            mapper,
            labelWidget,
            sphereSource,
            spheresArray,
            tempLabelsArray,
          };
        });
      };

      const resetView = async function () {
        loading.value = true;
        position.value = [1848.37, 3744.52, 2678.61];
        viewUp.value = [0.001, 0.0124, 1];
        setFullSensorChecked(false);
        setSpheresChecked(false);
        setSensor(0);
        setRepresentation(2);
        await createPipeline(renderer.value);
        loading.value = false;
      };

      const setViewDirection = function (posView) {
        isViewAngleChanged.value = true;
        position.value = posView.value.split(',');
        viewUp.value = posView.options[posView.options.selectedIndex].dataset.viewup.split(',');
      };

      // Gets a sphere radius according to telemetry and scale
      const getSphereRadius = async function (sensor, returnDefaultRadius = false) {
        const maxRadius = 300;
        const minRadius = 75;
        const defaultRadius = 200;
        let locationMultiplier = 1;
        const telemetry = await getLatestTelemetry();
        let locationX = Number(sensor.model_location[0]);
        let locationY = Number(sensor.model_location[1]);
        let locationZ = Number(sensor.model_location[2]);
        let radius = null;
        if (locationX < 0) {
          locationX *= -1;
        }
        if (locationY < 0) {
          locationY += -1;
        }
        if (locationZ < 0) {
          locationZ += -1;
        }
        if (locationX >= 10000 && locationY >= 10000 && locationZ >= 10000) {
          locationMultiplier = 10;
        } else if (locationX >= 5000 && locationY >= 5000 && locationZ >= 5000) {
          locationMultiplier = 2.5;
        } else if (locationX >= 2500 || locationY >= 2500 || locationZ >= 2500) {
          locationMultiplier = 2;
        } else if (locationX < 1000 && locationY < 1000 && locationZ < 1000) {
          locationMultiplier = 0.1;
        }

        if (returnDefaultRadius) {
          return (defaultRadius * locationMultiplier);
        }
        if (telemetry && Object.keys(telemetry).length > 0) {
          Object.entries(telemetry).map(([telemetryKey, telemetryItem]) => {
            let valuesArray;
            if (telemetryKey === sensor.name) {
              valuesArray = telemetryItem.map((a) => a.value);
              const max = Math.max(...valuesArray);
              // const min = Math.min(...valuesArray);
              let total = 0;
              for (let i = 0; i < valuesArray.length; i++) {
                total += Number(valuesArray[i]);
              }
              const averageTelemetry = total / valuesArray.length;
              let telemetryRadius;
              if (sensor.upper_threshold && sensor.lower_threshold) {
                let diff = 0;
                const avg = (sensor.upper_threshold + sensor.lower_threshold) / 2;
                if (averageTelemetry > avg) {
                  diff = Number(averageTelemetry / sensor.upper_threshold);
                } else {
                  diff = Number(averageTelemetry / sensor.lower_threshold);
                }
                if (diff < 0) {
                  diff *= diff;
                }
                telemetryRadius = maxRadius * diff;
              } else if (sensor.upper_threshold) {
                telemetryRadius = maxRadius * (averageTelemetry / sensor.upper_threshold);
              } else if (sensor.lower_threshold) {
                telemetryRadius = maxRadius * (averageTelemetry / sensor.lower_threshold);
              }

              if (!telemetryRadius) {
                telemetryRadius = maxRadius * (averageTelemetry / max);
              }
              if (telemetryRadius > maxRadius) {
                telemetryRadius = maxRadius;
              }

              if (telemetryRadius < minRadius) {
                telemetryRadius = minRadius;
              }

              radius = (telemetryRadius * locationMultiplier);
            }
          });
        }

        if (radius === null) {
          radius = (defaultRadius * locationMultiplier);
        }

        return radius;
      };

      const getSensors = async function () {
        let modelLocations;
        // Get the device sensors if they exist
        const device = getCurrentDevice();
        const telemetry = await getLatestTelemetry();
        if (device) {
          if (device.sensors.length > 0) {
            modelLocations = Object.entries(device.sensors).map(([key, item]) => {
              let sensor = {};
              if (item) {
                if (item.model_location !== null) {
                  let value = 0;
                  let region = null;
                  if (key >= 10) {
                    value = ((key - 10) + 1) / 10;
                  } else {
                    value = (key / 10) + 0.05;
                  }
                  if (item.region_id >= 0) {
                    region = item.region_id;
                  }
                  let latestTelemetry = null;
                  if (telemetry) {
                    Object.entries(telemetry).map(([telemetryKey, telemetryItem]) => {
                      let valuesArray;
                      if (telemetryKey === item.name) {
                        valuesArray = telemetryItem.map((a) => a.value);
                        const max = Math.max(...valuesArray);
                        const min = Math.min(...valuesArray);
                        let total = 0;
                        for (let i = 0; i < valuesArray.length; i++) {
                          total += Number(valuesArray[i]);
                        }
                        value = total / valuesArray.length;
                        latestTelemetry = value;
                        let divider = 100;
                        if (min > 100 && max > 100) {
                          if (min > 1000 && max > 1000) {
                            divider = 10000;
                            if (min > 10000 && max > 10000) {
                              divider = 100000;
                            }
                          }
                        }
                        if (item.upper_threshold && item.lower_threshold) {
                          const difference = Number(item.upper_threshold) + Number(item.lower_threshold);
                          const avg = difference / 2;
                          if (value > avg) {
                            value /= item.upper_threshold;
                          } else {
                            value /= item.lower_threshold;
                          }
                          if (value < 0) {
                            value *= -1;
                          }
                        } else if (item.upper_threshold) {
                          value /= item.upper_threshold;
                        } else if (item.lower_threshold) {
                          value /= item.lower_threshold;
                        } else {
                          value /= divider;
                        }
                      }
                    });
                  }

                  sensor = {
                    model_location: item.model_location,
                    name: item.name,
                    region_id: region,
                    value,
                    latestTelemetry,
                  };

                  if (item.lower_threshold) {
                    sensor.lower_threshold = Number(item.lower_threshold);
                  }

                  if (item.upper_threshold) {
                    sensor.upper_threshold = Number(item.upper_threshold);
                  }

                  return sensor;
                }
              }
              return null;
            });
          }
        }
        modelLocations = modelLocations.filter(Boolean);
        if (modelLocations.length === 0) {
          // Set up some fake sensors in case the device has none
          modelLocations = [
            {
              model_location: [13000, -20000, 29000],
              name: 'Sensor 1',
              value: 0.1,
            },
            {
              model_location: [34700, -20000, 29000],
              name: 'Sensor 2',
              value: 0.5,
            },
            {
              model_location: [55900, -20000, 28900],
              name: 'Sensor 3',
              value: 0.7,
            },
            //  { model_location: [77000, -20000, 28700] },
          ];
        }
        sensors.value = modelLocations;
        return modelLocations;
      };

      const getRgbFromInt = async function (int) {
        const tempInt = Number(int);
        const minimum = 0;
        const maximum = 1;
        const ratio = (2 * (tempInt - minimum)) / (maximum - minimum);
        const b = Number(Math.max(0, 255 * (1 - ratio)));
        const r = Number(Math.max(0, 255 * (ratio - 1)));
        const g = 255 - b - r;
        return [r, g, b];
      };

      // const renderAlarms = async function () {
      //   function renderEntity(renderer, entity, center, source) {
      //     const actor = vtkActor.newInstance();
      //     const mapper = vtkMapper.newInstance();
      //     const polydata = source.getOutputData();
      //
      //     vtkMatrixBuilder
      //       .buildFromRadian()
      //       .translate(...center)
      //       .rotateFromDirections([1, 0, 0], [0, 1, 0], [0, 0, 1])
      //       .apply(polydata.getPoints().getData().map((datum) => datum));
      //
      //     mapper.setInputData(polydata);
      //     actor.setMapper(mapper);
      //     renderer.addActor(actor);
      //     mapper.modified();
      //     actor.modified();
      //     return {
      //       entity,
      //       actor,
      //       polydata,
      //       mapper,
      //       source,
      //     };
      //   }
      //
      //   // const renderer = context.value.fullScreenRenderer.getRenderer();
      //   // const renderWindow = context.value.fullScreenRenderer.getRenderWindow();
      //   // alarmVtk.value.map((alarmVtkN) => renderer.removeActor(alarmVtkN.actor));
      //   // sensorVtk.value.map((sensorVtkN) => renderer.removeActor(sensorVtkN.actor));
      //
      //   // renderWindow.render();
      // };

      const render = async function () {
        const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
          rootContainer: vtkContainer.value,
          containerStyle: {
            minheight: '200px', height: '50rem', width: '100%', position: 'relative',
          },
          background: [1, 1, 1],
        });
        renderer.value = fullScreenRenderer;
        await createPipeline(fullScreenRenderer);
      };

      // const getAxes = async function () {
      //   if (!axesConst.value) {
      //     // create axes
      //     const axes = vtkAnnotatedCubeActor.newInstance();
      //     axes.setDefaultStyle({
      //       text: '+X',
      //       fontStyle: 'bold',
      //       fontFamily: 'Arial',
      //       fontColor: 'black',
      //       fontSizeScale: (res) => res / 2,
      //       faceColor: '#0000ff',
      //       faceRotation: 0,
      //       edgeThickness: 0.1,
      //       edgeColor: 'black',
      //       resolution: 400,
      //     });
      //     // axes.setXPlusFaceProperty({ text: '+X' });
      //     axes.setXMinusFaceProperty({
      //       text: '-X',
      //       faceColor: '#ffff00',
      //       faceRotation: 90,
      //       fontStyle: 'italic',
      //     });
      //     axes.setYPlusFaceProperty({
      //       text: '+Y',
      //       faceColor: '#00ff00',
      //       fontSizeScale: (res) => res / 4,
      //     });
      //     axes.setYMinusFaceProperty({
      //       text: '-Y',
      //       faceColor: '#00ffff',
      //       fontColor: 'white',
      //     });
      //     axes.setZPlusFaceProperty({
      //       text: '+Z',
      //       edgeColor: 'yellow',
      //     });
      //     axes.setZMinusFaceProperty({text: '-Z', faceRotation: 45, edgeThickness: 0});
      //     axesConst.value = axes;
      //     return axes;
      //   } else {
      //     return axesConst.value;
      //   }
      // }
      const hideAllSpheres = async function (sphereSource, spheresArray) {
        sphereSource.setRadius(0);

        Object.entries(spheresArray).map(([, arrayValue]) => {
          arrayValue.setRadius(0);
        });

        spheresArray.splice(0, spheresArray.length);
      };

      const disableTempLabels = async function (tempLabelsArray) {
        Object.entries(tempLabelsArray).map(([, tempLabel]) => {
          tempLabel.setEnabled(0);
        });

        tempLabelsArray.splice(0, tempLabelsArray.length);
      };

      const regionToSensor = function (regionId, sensorsTemp) {
        let sensor = sensorsTemp.filter((sensor) => Number(sensor.region_id) === Number(regionId));
        if (sensor.length > 0) {
          [sensor] = sensor;
          return sensor.value;
        }
        return 1;
      };

      // const locationToValue = function (x, spheresArray) {
      //   let sphere = spheresArray.filter((sphere) => Number(sphere.getCenter()[0]) === Number(x[0]) ||  Number(sphere.getCenter()[1]) === Number(x[1]) || Number(sphere.getCenter()[2]) === Number(x[2]));
      //   if (sphere.length > 0) {
      //     [sphere] = sphere;
      //     return sphere.value;
      //   }
      //   return 1;
      // };

      watchEffect(async () => {
        const rep = unref(representation);
        const sens = unref(sensor);
        const currentAlarm = unref(getCurrentAlarm());
        if (context.value) {
          const {
            simpleFilter,
            actor,
            orientationWidget,
            renderWindow,
            applyPreset,
            renderer,
            labelWidget,
            sphereSource,
            spheresArray,
            tempLabelsArray,
          } = context.value;
          actor.getProperty().setRepresentation(rep);
          const sensorsTemp = await getSensors();
          let activeSensor = sensorsTemp[sens];
          if (currentAlarm !== null) {
            const tempActiveSensor = sensorsTemp.filter((sensor) => sensor.name === currentAlarm.originator);
            if (tempActiveSensor.length > 0) {
              [activeSensor] = tempActiveSensor;
              setSensor(sensorsTemp.indexOf(activeSensor));
              activeSensor.latestTelemetry = currentAlarm.value;
              setSensorSelectorDisabled(true);
              fullSensorCbDisabled.value = true;
            }
          } else {
            setSensorSelectorDisabled(false);
            fullSensorCbDisabled.value = false;
          }
          const loc = activeSensor.model_location;

          if ((!spheresChecked.value && spheresArray.length > 0) || (!fullSensorChecked.value && spheresArray.length > 1)) {
            await hideAllSpheres(sphereSource, spheresArray);
            await disableTempLabels(tempLabelsArray);
          }

          let labelText = 'No telemetry for this sensor';
          if (activeSensor.latestTelemetry !== null && activeSensor.latestTelemetry !== undefined) {
            labelText = activeSensor.latestTelemetry.toString();
          }

          labelWidget.getWidgetRep().setLabelText(labelText);
          const center = [Number(loc[0]), Number(loc[1]), Number(loc[2])];
          labelWidget.getWidgetRep().setWorldPosition(center);

          if (sens !== undefined) {
            const vtpReader = vtkXMLPolyDataReader.newInstance();
            if (!isSensorChanged.value && !isRepresentationChanged.value && !isSpheresCheckboxChanged.value) {
              let index = getCurrentDevice().model.findIndex((item) => item.type === 'default');
              if (index === -1 || (getCurrentDevice().model.length > 1 && fullSensorChecked.value)) {
                setFullSensorChecked(true);
                if (index === -1) {
                  fullSensorCbDisabled.value = true;
                }
                index = getCurrentDevice().model.findIndex((item) => item.type === 'full-sensor');
              }
              const modelId = getCurrentDevice().model[index].id;
              const vtpUrl = await getVtpUrl(modelId);

              vtpReader.setUrl(vtpUrl, { loadData: true }).then(async () => {
                vtpReader.update();
                const lookupTable = vtkColorTransferFunction.newInstance();
                const warpScalar = vtkWarpScalar.newInstance();

                const mapper = vtkMapper.newInstance({
                  interpolateScalarsBeforeMapping: true,
                  colorMode: ColorMode.DEFAULT,
                  scalarMode: ScalarMode.DEFAULT,
                  useLookupTableScalarRange: true,
                  lookupTable,
                  scalarVisibility: true,
                });

                simpleFilter.setInputConnection(vtpReader.getOutputPort());
                simpleFilter.update();
                warpScalar.setInputConnection(simpleFilter.getOutputPort());
                const actor = vtkActor.newInstance();
                warpScalar.update();
                // const source = warpScalar;
                applyPreset();

                actor.getProperty().setEdgeVisibility(false);
                actor.getProperty().setDiffuse(0.9);
                actor.getProperty().setOpacity(0.5);
                actor.getProperty().setSpecular(0.2);
                actor.getProperty().setSpecularPower(30);
                actor.getProperty().setSpecularColor(1.0, 1.0, 1.0);
                actor.getProperty().setAmbient(0.25);
                actor.setMapper(mapper);

                if (spheresChecked.value && !fullSensorChecked.value) {
                  const appendPolyData = vtkAppendPolyData.newInstance();
                  const radius = await getSphereRadius(activeSensor);
                  sphereSource.setRadius(radius);
                  sphereSource.setPhiResolution(20);
                  sphereSource.setThetaResolution(20);
                  sphereSource.setCenter(Number(loc[0]), Number(loc[1]), Number(loc[2]));
                  if (spheresArray.length < 1) {
                    spheresArray.push(sphereSource);
                  }
                  appendPolyData.setInputConnection(sphereSource.getOutputPort());
                  mapper.setInputConnection(appendPolyData.getOutputPort());
                } else if (fullSensorChecked.value && spheresChecked.value && getCurrentDevice().model.length < 2) {
                  await hideAllSpheres(sphereSource, spheresArray);
                  await disableTempLabels(tempLabelsArray);
                  const appendPolyData = vtkAppendPolyData.newInstance();

                  Object.entries(sensorsTemp).map(async ([, tempSensor]) => {
                    const tempMapper = vtkMapper.newInstance({
                      interpolateScalarsBeforeMapping: true,
                      colorMode: ColorMode.DEFAULT,
                      scalarMode: ScalarMode.DEFAULT,
                      useLookupTableScalarRange: true,
                      lookupTable,
                      scalarVisibility: true,
                    });
                    const sphereTemp = vtkSphereSource.newInstance();
                    const radius = await getSphereRadius(tempSensor, true);
                    sphereTemp.setRadius(radius);
                    const tempLabelWidget = vtkLabelWidget.newInstance();
                    tempLabelWidget.setInteractor(renderWindow.getInteractor());
                    tempLabelWidget.setEnabled(1);
                    tempLabelWidget.getWidgetRep().setLabelStyle({
                      fontSize: 12,
                    });
                    tempLabelWidget.getWidgetRep().setLabelText(tempSensor.name);
                    const center = [Number(tempSensor.model_location[0]),
                      Number(tempSensor.model_location[1]),
                      Number(tempSensor.model_location[2]),
                    ];
                    tempLabelWidget.getWidgetRep().setWorldPosition(center);
                    sphereTemp.setCenter(Number(tempSensor.model_location[0]), Number(tempSensor.model_location[1]), Number(tempSensor.model_location[2]));
                    tempLabelsArray.push(tempLabelWidget);
                    spheresArray.push(sphereTemp);
                    const sphereActor = vtkActor.newInstance();
                    const rgbColors = await getRgbFromInt(tempSensor.value);
                    sphereActor.getProperty().setColor(rgbColors[0], rgbColors[1], rgbColors[2]);
                    sphereActor.getProperty().setEdgeVisibility(false);
                    sphereActor.getProperty().setOpacity(0.5);
                    sphereActor.setVisibility(true);
                    sphereActor.setPickable(true);
                    sphereActor.setMapper(tempMapper);
                    renderer.addActor(sphereActor);
                    if (Number(tempSensor) === 0) {
                      appendPolyData.setInputConnection(sphereTemp.getOutputPort());
                    } else {
                      appendPolyData.addInputConnection(sphereTemp.getOutputPort());
                    }
                    tempMapper.setInputConnection(sphereTemp.getOutputPort());
                    renderer.resetCamera();
                    renderWindow.render();
                  });
                } else if (getCurrentDevice().model.length > 1) {
                  await hideAllSpheres(sphereSource, spheresArray);
                  await disableTempLabels(tempLabelsArray);
                }
                mapper.update();
                renderer.addActor(actor);
                renderer.getActiveCamera().setViewUp(viewUp.value[0], viewUp.value[1], viewUp.value[2]);
                renderer.getActiveCamera().setPosition(position.value[0], position.value[1], position.value[2]);
                renderer.getActiveCamera().setFocalPoint(-4618.41, -5814.2, 2803.9);
                renderer.getActiveCamera().setViewAngle(30);
                renderer.resetCamera();
                renderWindow.render();
                applyPreset();

                orientationWidget.setActor(axesConst.value);
                orientationWidget.setInteractor(renderWindow.getInteractor());
                orientationWidget.setEnabled(true);
                orientationWidget.setViewportCorner(
                  vtkOrientationMarkerWidget.Corners.TOP_RIGHT,
                );
                orientationWidget.setViewportSize(0.15);
                orientationWidget.setMinPixelSize(100);
                orientationWidget.setMaxPixelSize(300);
              });
            }
          }
          renderer.addActor(actor);
          if (spheresChecked.value) {
            const radius = await getSphereRadius(activeSensor);
            sphereSource.setRadius(radius);
            sphereSource.setPhiResolution(20);
            sphereSource.setThetaResolution(20);
          }
          sphereSource.setCenter(Number(loc[0]), Number(loc[1]), Number(loc[2]));
          if (spheresArray.length < 1) {
            spheresArray.push(sphereSource);
          }
          renderer.resetCamera();
          renderWindow.render();
          applyPreset();
          if (fullSensorChecked.value && getCurrentDevice().model.length > 1) {
            labelWidget.setEnabled(0);
            simpleFilter.setFormulaSimple(
              FieldDataTypes.POINT,
              ['Regions'],
              'Result2',
              (Regions) => regionToSensor(Regions, sensorsTemp),
            );
          } else if (fullSensorChecked.value) {
            labelWidget.setEnabled(0);
            // actor.getProperty().setColor(255,0,0);
            simpleFilter.setFormulaSimple(
              FieldDataTypes.POINT,
              [],
              'Result2',
              () => -1,
            );
          } else if (!fullSensorChecked.value) {
            labelWidget.setEnabled(1);
            simpleFilter.setFormulaSimple(
              FieldDataTypes.POINT,
              [],
              'Result2',
              (x) => (1 / (1 + Math.abs(x[0] - loc[0]) + Math.abs(x[1] - loc[1]) + Math.abs(x[2] - loc[2]))),
            );
          }
          isRepresentationChanged.value = false;
          isSensorChanged.value = false;
          isFullSensorChanged.value = false;
          isSpheresCheckboxChanged.value = false;
          isViewAngleChanged.value = false;
          loading.value = false;
        }
      });

      context.value = null;

      onBeforeUnmount(() => {
        if (context.value) {
          const { actor, mapper } = context.value;
          actor.delete();
          mapper.delete();
          context.value.fullScreenRenderer.delete();
          context.value = null;
        }
      });

      onMounted(() => {
        if (!context.value) {
          render();
        }
      });

      return {
        setRepresentation,
        setSensor,
        setViewDirection,
        getCurrentDevice,
        getSensors,
        setFullSensorChecked,
        setSpheresChecked,
        fullSensorCbDisabled,
        sensorSelectorDisabled,
        resetView,
        isFullSensorChanged,
        isSpheresCheckboxChanged,
        isSensorChanged,
        loading,
        isRepresentationChanged,
        isViewAngleChanged,
        simpleFilter,
        representation,
        sensors,
        sensor,
        context,
        position,
        viewUp,
        fullSensorChecked,
        spheresChecked,
        vtkContainer,
        render,
        sensorVtk,
        alarmVtk,
      };
    }
    return null;
  },

  computed: {
    ...mapState('alarms', ['alarms', 'currentAlarm']),
  },

  watch: {
  },
};
</script>

<style scoped>
.vtkContainerOuter {
  position: relative;
}
.controls {
  position: absolute;
  top: 3.5rem;
  left: 1.5rem;
  background: white;
  padding: 12px;
  margin-top: 4rem;
}

.colorLegend{
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  background: white;
  padding: 0.9rem;
}
</style>
