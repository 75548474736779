import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();

const alarms = {
  namespaced: true,
  state: {
    filesLoading: false,
    reports: [],
    vtpUrl: null,
  },
  mutations: {
    setFilesLoading(state, filesLoading) {
      state.filesLoading = filesLoading;
    },
    setVtpUrl(state, vtpUrl) {
      state.vtpUrl = vtpUrl;
    },
    setReports(state, reports) {
      state.reports = reports;
    },
  },
  actions: {
    async uploadReport({ commit }, formData) {
      try {
        commit('setFilesLoading', true);
        await axios.post('/upload-reports',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        commit('setFilesLoading', false);
        toast.success('Successfully uploaded a report');
      } catch (error) {
        console.error(error);
        commit('setFilesLoading', false);
      }
    },
    async uploadVtp({ commit }, formData) {
      try {
        commit('setFilesLoading', true);
        await axios.post('/upload-vtp',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        commit('setFilesLoading', false);
        toast.success('Successfully uploaded a device model');
      } catch (error) {
        console.error(error);
        commit('setFilesLoading', false);
      }
    },
    async getVtpUrl({ commit }, vtpId) {
      await axios.get(`/vtps/${vtpId}`, {
        params: {
          id: vtpId,
        },
      })
        .then((response) => {
          commit('setVtpUrl', response.data);
        });
    },
    async launchReport(vuex, reportId) {
      axios.get(`/reports/${reportId}`, {
        responseType: 'blob',
      })
        .then((response) => {
          const blob = new File([response.data], 'sample.pdf', {
            type: 'application/pdf',
          });
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = window.URL.createObjectURL(blob);
          link.click();
        });
    },
    async deleteVtp({ commit }, vtpId) {
      commit('setFilesLoading', true);
      await axios.get(`/vtps/delete/${vtpId}`, {
        params: {
          id: vtpId,
        },
      })
        .then(() => {
          toast.success('The VTP has been deleted');
          commit('setFilesLoading', false);
        });
    },
    async deleteReport({ commit }, reportId) {
      commit('setFilesLoading', true);
      await axios.get(`/reports/delete/${reportId}`, {
        params: {
          id: reportId,
        },
      })
        .then((response) => {
          commit('setReports', response.data);
          toast.success('The report has been deleted');
          commit('setFilesLoading', false);
        });
    },
    async loadReports({ commit }) {
      commit('setFilesLoading', true);
      try {
        const response = await axios.get('/documents');
        commit('setReports', response.data);
      } catch (e) {
        commit('setFilesLoading', false);
      }
      commit('setFilesLoading', false);
    },
  },
};
export default alarms;
