import axios from 'axios';
import moment from 'moment';
import { useToast } from 'vue-toastification';

const toast = useToast();

const alarms = {
  namespaced: true,
  state: {
    alarms: [],
    currentAlarm: null,
    alarmsLoading: false,
  },
  mutations: {
    setAlarms(state, data) {
      state.alarms = data;
    },
    setCurrentAlarm(state, alarm) {
      state.currentAlarm = alarm;
    },
    setAlarmsLoading(state, loading) {
      state.alarmsLoading = loading;
    },
  },
  actions: {
    async mapAlarms({ commit }, alarmsToMap) {
      commit('setCurrentAlarm', null);
      try {
        commit('setAlarmsLoading', true);
        let alarmsToMapTemp = alarmsToMap;
        if (alarmsToMap.alarmsToMap) {
          alarmsToMapTemp = alarmsToMap.alarmsToMap;
        }
        const tempAlarms = Object.entries(alarmsToMapTemp).map(([, item]) => {
          if (item) {
            const threshold = item.threshold ? item.threshold : null;
            return {
              time: moment(item.timestamp).format('DD/MM/YY H:mm'),
              originator: item.originator,
              status: item.status,
              note: item.note,
              ts: item.timestamp,
              gifUrl: item.gif_url,
              threshold,
            };
          }
          return null;
        });
        commit('setAlarms', tempAlarms);
        commit('setAlarmsLoading', false);
      } catch (error) {
        console.error(error);
        commit('setAlarmsLoading', false);
      }
    },
    async updateAlarmStatus({ commit }, formData) {
      try {
        commit('setAlarmsLoading', true);
        await axios.post('/change-alarm-status', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        toast.success('Alarm status changed');
        commit('setAlarmsLoading', false);
      } catch (error) {
        console.error(error);
        commit('setAlarmsLoading', false);
      }
    },
    async refreshAlarms({ commit, dispatch }, deviceId) {
      const config = {
        params: {
          deviceId,
        },
      };
      try {
        commit('setAlarmsLoading', true);
        const response = await axios.get('/getAlarms', config);
        dispatch('mapAlarms', response.data);
        commit('setAlarmsLoading', false);
      } catch (error) {
        console.error(error);
        commit('setAlarmsLoading', false);
      }
    },
  },
  getters: {
    getAlarms: (state) => state.alarms,
  },
};
export default alarms;
