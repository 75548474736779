<template>
  <div>
    <top-nav>
    </top-nav>
      <router-view />
  </div>
</template>

<script>
import topNav from './components/TopNav.vue';

export default {
  name: 'App',
  components: {
    topNav,
  },
};
</script>
