<template>
    <div v-if="!loading" class="mt-5 w-75 mx-auto bg-white-gray-text pb-5">
      <h4 class="mt-5 pt-4">
        How can we help you?
      </h4>

      <div class="w-75 mx-auto">
        <p>Browse or search are Frequently Asked Questions below:</p>
        <span class="p-input-icon-left w-100 mb-4">
          <i class="pi pi-search" />
          <input-text class="w-100" type="text" v-model="search" placeholder="Search" />
        </span>
        <div v-if="isAdmin()" class="d-flex">
          <pv-button
              @click="toggle"
              class="btn btn-black mr-auto mb-4"
              icon="pi pi-plus"
              label="Add an article"
              v-tooltip="'Opens a panel where a new FAQ article could be added'"
          />
        </div>

        <overlay-panel ref="op" class="w-50">
          <div class="d-flex flex-column w-100">
            <input-text id="articleTitle" class="mb-2" type="text" v-model="newArticle.title" placeholder="Title" maxlength="500"/>
            <small
                :id="'create-title-help'"
                class="p-error col-12 d-none mb-2"
            >Article title cannot be empty</small>
            <textarea id="articleContents" class="mb-2" v-model="newArticle.contents" rows="5" cols="5" placeholder="Contents" maxlength="1000" />
            <small
                :id="'create-contents-help'"
                class="p-error col-12 d-none"
            >Article contents cannot be empty
            </small>
            <pv-button
                @click="createArticle"
                class="btn btn-black m-auto mb-4"
                :disabled="buttonsDisabled"
                v-tooltip="'Creates the article'"
            >
              Create <i v-if="buttonsDisabled" class='fa fa-circle-o-notch fa-spin'></i>
            </pv-button>
          </div>
        </overlay-panel>
        <overlay-panel ref="opEdit" class="w-50">
          <div class="d-flex flex-column w-100">
            <input-text id="articleToEditTitle" class="mb-2" type="text" v-model="articleToEdit.title" placeholder="Title" />
            <small
                :id="'update-title-help'"
                class="p-error col-12 d-none"
            >Article title cannot be empty
            </small>
            <textarea id="articleToEditContents" class="mb-2" v-model="articleToEdit.contents" rows="5" cols="5" placeholder="Contents"/>
            <small
                :id="'update-contents-help'"
                class="p-error col-12 d-none"
            >Article contents cannot be empty
            </small>
            <pv-button
                @click="updateSelectedArticle"
                class="btn btn-black m-auto mb-4"
                :disabled="buttonsDisabled"
                v-tooltip="'Updates the article'"
            >
              Update <i v-if="buttonsDisabled" class='fa fa-circle-o-notch fa-spin'></i>
            </pv-button>
          </div>
        </overlay-panel>
        <overlay-panel ref="opDelete" class="w-50">
          <div class="d-flex flex-column w-100">
            <h4 class="mx-auto">Are you sure you want to delete this help article?</h4>
            <div class="mx-auto">
              <pv-button
                  @click="deleteSelectedArticle(articleIdToDelete)"
                  class="btn btn-black mb-4 mr-2"
                  :disabled="buttonsDisabled"
              >
                Yes <i v-if="buttonsDisabled" class='fa fa-circle-o-notch fa-spin'></i>
              </pv-button>
              <pv-button
                  @click="toggleDeleteConfirmation($event)"
                  class="btn btn-black mb-4"
                  :disabled="buttonsDisabled"
              >
                No <i v-if="buttonsDisabled" class='fa fa-circle-o-notch fa-spin'></i>
              </pv-button>
            </div>
          </div>
        </overlay-panel>
        <accordion>
          <accordion-tab v-for="article in filteredArticles" :key="article.title">
            <template #header>
              <span class="text-left">{{ article.title }}</span>
              <i v-if="!buttonsDisabled && isAdmin()" @click="toggleDeleteConfirmation($event, article.id)" class='ml-auto fa fa-times cursor-pointer'></i>
              <i v-else-if="buttonsDisabled" class='ml-auto fa fa-circle-o-notch fa-spin'></i>
            </template>
            <p class="text-left">{{ article.contents }}</p>
            <div v-if="isAdmin()" class="d-flex">
              <pv-button
                  :disabled="buttonsDisabled"
                  @click="toggleEdit($event, article)"
                  class="btn btn-black mr-auto mb-4"
                  icon="pi pi-pencil"
                  label="Edit article"
                  v-tooltip="'Opens a panel where a FAQ article could be edited'"
              />
            </div>
          </accordion-tab>
        </accordion>
      </div>
    </div>
    <h4 class="mt-5" v-else>
      Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
    </h4>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
// import { useToast } from 'vue-toastification';
//
// const toast = useToast();

export default {
  data() {
    return {
      search: '',
      articleIdToDelete: null,
      loading: true,
      buttonsDisabled: false,
      newArticle: {
        title: null,
        contents: null,
      },
      articleToEdit: {
        title: null,
        contents: null,
      },
    };
  },
  setup() {
    const store = useStore();
    return {
      updateArticle: (formData) => store.dispatch('helpArticles/updateArticle', formData),
      deleteArticle: (articleId) => store.dispatch('helpArticles/deleteArticle', articleId),
      createArticleInStore: (formData) => store.dispatch('helpArticles/createArticle', formData),
      getHelpArticles: () => store.dispatch('helpArticles/getArticles'),
      helpArticles: computed(() => store.state.helpArticles.helpArticles),
      userRole: computed(() => store.state.users.userRole),
    };
  },
  async mounted() {
    await this.getHelpArticles();
    this.loading = false;
  },
  computed: {
    filteredArticles() {
      const re = new RegExp(this.search, 'gi');
      return this.helpArticles.filter((article) => {
        if (article.title && article.title.match(re)) {
          return article.title.match(re);
        }
        if (article.contents) {
          return article.contents.match(re);
        }
        return null;
      });
    },
  },
  methods: {
    isAdmin() {
      return this.userRole === 'admin';
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    toggleEdit(event, article) {
      this.articleToEdit = article;
      this.$refs.opEdit.toggle(event);
    },
    toggleDeleteConfirmation(event, articleId) {
      if (articleId) {
        this.articleIdToDelete = articleId;
      }
      this.$refs.opDelete.toggle(event);
    },
    async createArticle() {
      this.buttonsDisabled = true;
      let canSubmit = true;
      if (!this.newArticle.title) {
        canSubmit = false;
        this.toggleInvalidFields('articleTitle', 'create-title-help', true);
        this.buttonsDisabled = false;
      }

      if (!this.newArticle.contents) {
        canSubmit = false;
        this.toggleInvalidFields('articleContents', 'create-contents-help', true);
        this.buttonsDisabled = false;
      }

      if (canSubmit) {
        if (!document.getElementById('create-title-help').classList.contains('d-none')) {
          this.toggleInvalidFields('articleTitle', 'create-title-help', false);
        }
        if (document.getElementById('articleContents').classList.contains('p-invalid')) {
          this.toggleInvalidFields('articleContents', 'create-contents-help', false);
        }
        const formData = new FormData();
        formData.append('title', this.newArticle.title);
        formData.append('contents', this.newArticle.contents);
        await this.createArticleInStore(formData);
        this.$refs.op.hide();
        this.newArticle.title = null;
        this.newArticle.contents = null;
        this.buttonsDisabled = false;
      }
    },
    async deleteSelectedArticle(articleId) {
      this.buttonsDisabled = true;
      await this.deleteArticle(articleId);
      this.$refs.opDelete.hide();
      this.articleIdToDelete = null;
      this.buttonsDisabled = false;
    },
    async updateSelectedArticle() {
      this.buttonsDisabled = true;
      let canSubmit = true;
      if (!this.articleToEdit.title) {
        canSubmit = false;
        this.toggleInvalidFields('articleToEditTitle', 'update-title-help', true);
        this.buttonsDisabled = false;
      }

      if (!this.articleToEdit.contents) {
        canSubmit = false;
        this.toggleInvalidFields('articleToEditContents', 'update-contents-help', true);
        this.buttonsDisabled = false;
      }

      if (canSubmit) {
        if (!document.getElementById('update-title-help').classList.contains('d-none')) {
          this.toggleInvalidFields('articleToEditTitle', 'update-title-help', false);
        }
        if (document.getElementById('articleToEditContents').classList.contains('p-invalid')) {
          this.toggleInvalidFields('articleToEditContents', 'update-contents-help', false);
        }
        const formData = new FormData();
        formData.append('title', this.articleToEdit.title);
        formData.append('contents', this.articleToEdit.contents);
        formData.append('id', this.articleToEdit.id);
        await this.updateArticle(formData);
        this.$refs.opEdit.hide();
        this.buttonsDisabled = false;
      }
    },
    toggleInvalidFields(fieldId, tooltipId, toggle) {
      if (toggle) {
        document.getElementById(fieldId).classList.add('p-invalid');
        document.getElementById(tooltipId).classList.remove('d-none');
      } else {
        document.getElementById(fieldId).classList.remove('p-invalid');
        document.getElementById(tooltipId).classList.add('d-none');
      }
    },
  },
};
</script>
