<template>
  <div v-if="!loading">
      <chart type="scatter" :options="options" :data="optionsForChart.data" ref="chart">
      </chart>
    <h5 v-if="this.optionsForChart.data.datasets.length <= 70">Click on a sensor to hide it from the graph</h5>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'TelemetryChart',
  props: {
    telemetry: {
      type: Object,
    },
    alarmSelected: {
      type: Boolean,
    },
    selectedDevice: {
      type: Object,
    },
    clickedAlarm: {
      type: Object,
    },
  },
  setup() {
    const chart = ref(null);
    const store = useStore();

    return {
      selectCurrentAlarm: (alarm) => store.commit('alarms/setCurrentAlarm', alarm),
      chart,
    };
  },
  data() {
    return {
      loading: true,
      yLabel: 'Height',
      xLabel: 'Width',
      hexColours: ['#484b4f', '#606060', '#757575', '#909090',
        '#d2b2e5', '#e6b6b0', '#a1a8c7', '#ad5a99', '#688178', '#e3dbc1',
        '#a2e382', '#d3acac', '#39394f', '#5b855b', '#d3acac',
        '#404f3c', '#727e71', '#5d4b4b', '#656596',
        '#a1957d', '#94b9b1'],
      optionsForChart: {
        type: 'scatter',
        data: {
          datasets: [],
        },
      },
    };
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          display: this.optionsForChart.data.datasets.length <= 70,
        },
        scales: {
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              scaleLabel: {
                display: true,
                labelString: this.yLabel,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              position: 'bottom',
              id: 'x-axis-1',
              scaleLabel: {
                display: true,
                labelString: this.xLabel,
              },
            }],
        },

      };
    },
  },
  async mounted() {
    if (Object.keys(this.telemetry).length > 0) {
      Object.entries(this.telemetry).map(([key, value]) => {
        this.optionsForChart.data.datasets.push({
          label: key,
          showLine: true,
          fill: false,
          data: value,
          borderColor: this.hexColours[Math.floor(Math.random() * this.hexColours.length)],
          lineTension: 0,
        });
      });
    }
    this.loading = false;
  },
  methods: {
  },
};
</script>
