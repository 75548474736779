<template>
  <div class="mt-5 w-75 mx-auto bg-white-gray-text pb-5" v-if="!loading">
    <h4 class="mt-5 pt-4">
      Analytics
    </h4>
    <div class="w-75 mx-auto">
      <iframe plausible-embed
              id="analyticsEmbed"
              :src="analyticsSrc"
              scrolling="no"
              frameborder="0"
              class="analytics-iframe">
      </iframe>
    </div>
  </div>
  <div v-else class="mt-5 w-75 mx-auto">
      <h4 class="mt-5">
        Loading info from the database <i class='fa fa-circle-o-notch fa-spin'></i>
      </h4>
    </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { computed } from 'vue';
import { useStore } from 'vuex';
import router from '../router';

export default {
  data() {
    return {
      analyticsSrc: 'https://plausible.io/share/fat-mrn-dev.ma.openindustry.in?auth=OESyWB_fPvUzNg9hIfMoC&embed=true&theme=light&background=%23FFFFFF',
    };
  },
  setup() {
    const store = useStore();
    return {
      loading: computed(() => store.state.users.userLoading),
      userRole: computed(() => store.state.users.userRole),
      isAdmin: () => store.dispatch('users/isAdmin'),
    };
  },
  async mounted() {
    if (this.userRole !== 'admin') {
      router.push({ name: 'Home' });
    } else {
      // checks if the user has permissions to view the page
      await this.isAdmin();
    }
    if (process.env.VUE_APP_SERVER_URL === 'https://monolith-app.flaxandteal.co.uk') {
      this.analyticsSrc = 'https://plausible.io/share/monolith-app.flaxandteal.co.uk?auth=zayGL1tC4AJOwJfWKq_hB&embed=true&theme=light&background=%23FFFFFF';
    }
  },
};
</script>
