<template>
   <dropdown
    v-model="selectedDevice"
    :options="options"
    :class="classProp"
    id="device"
    ref="device"
    panelClass="ml-2"
    @change="selectDevice($event)"
    optionLabel="name"
    placeholder="Select a device"
    filter>
</dropdown>
</template>

<script>

export default {
  name: 'DeviceSelector',
  props: {
    options: {
      type: Array,
    },
    classProp: {
      type: String,
    },
    initialDevice: {
      type: Object,
      default: null,
    },
  },
  emits: ['deviceChanged'],
  data() {
    return {
      selectedDevice: null,
    };
  },
  mounted() {
    this.selectedDevice = this.initialDevice;
  },
  watch: {
    initialDevice(newVal) {
      this.selectedDevice = newVal;
    },
  },
  methods: {
    async selectDevice(device) {
      this.selectedDevice = device.value;
      this.$emit('deviceChanged', device.value);
    },
  },
};
</script>
