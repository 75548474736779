import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();

const helpArticles = {
  namespaced: true,
  state: {
    helpArticles: [],
  },
  mutations: {
    setHelpArticles(state, data) {
      state.helpArticles = data;
    },
  },
  actions: {
    async updateArticle({ commit }, formData) {
      try {
        const url = `/help-articles/${formData.get('id')}`;
        await axios.post(url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            commit('setHelpArticles', response.data);
            toast.success('Successfully updated a help article');
          });
      } catch (e) {
        toast.error('An error has occurred while updating article. Please try again');
      }
    },
    async deleteArticle({ commit }, articleId) {
      try {
        await axios.delete(`/help-articles/${articleId}`, {
          params: {
            id: articleId,
          },
        })
          .then((response) => {
            commit('setHelpArticles', response.data);
            toast.success('The article has been deleted');
          });
      } catch (e) {
        toast.error('An error has occurred while deleting article. Please try again');
      }
    },
    async createArticle({ commit }, formData) {
      try {
        await axios.post('/help-articles',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            commit('setHelpArticles', response.data);
            toast.success('Successfully created a help article');
          });
      } catch (e) {
        toast.error('An error has occurred while adding article. Please try again');
      }
    },
    async getArticles({ commit }) {
      try {
        await axios.get('help-articles')
          .then((response) => {
            commit('setHelpArticles', response.data);
          });
      } catch (e) {
        toast.error('An error has occurred while retrieving articles');
      }
    },
  },
};
export default helpArticles;
