<template>
    <div class="mt-5 w-75 mx-auto">
      <h4 class="mt-5">
        Logging you in, please wait  <i class='fa fa-circle-o-notch fa-spin'></i>
      </h4>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  setup() {
    const store = useStore();
    return {
      loading: computed(() => store.state.users.userLoading),
      getUser: (accessToken) => store.dispatch('users/getUser', accessToken),
    };
  },
  async mounted() {
    await this.getUser(this.$route.query);
  },
};
</script>
