<template>
  <div v-if="!loading">
      <chart type="line" :options="options" :data="basicData" ref="chart">
      </chart>
    <h5 v-if="this.basicData.datasets.length <= 70">Click on a sensor to hide it from the graph</h5>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  name: 'TelemetryChart',
  props: {
    telemetry: {
      type: Object,
    },
    alarmSelected: {
      type: Boolean,
    },
    selectedDevice: {
      type: Object,
    },
    clickedAlarm: {
      type: Object,
    },
  },
  setup() {
    const chart = ref(null);
    const store = useStore();

    return {
      selectCurrentAlarm: (alarm) => store.commit('alarms/setCurrentAlarm', alarm),
      chart,
    };
  },
  data() {
    return {
      loading: true,
      chartSymbolAxis1: 'µε',
      chartSymbolAxis2: '',
      axis1Label: '',
      axis2Label: '',
      secondAxisTaken: false,
      hexColours: ['#484b4f', '#606060', '#757575', '#909090',
        '#d2b2e5', '#e6b6b0', '#a1a8c7', '#ad5a99', '#688178', '#e3dbc1',
        '#a2e382', '#d3acac', '#39394f', '#5b855b', '#d3acac',
        '#404f3c', '#727e71', '#5d4b4b', '#656596',
        '#a1957d', '#94b9b1'],
      basicData: {
        labels: [
        ],
        datasets: [
        ],
      },
    };
  },
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          display: this.basicData.datasets.length <= 70,
        },
        scales: {
          yAxes: [
            {
              type: 'linear',
              display: true,
              position: 'left',
              id: 'y-axis-1',
              ticks: {
                callback: (value) => `${value} ${this.chartSymbolAxis1}`,
              },
              scaleLabel: {
                display: true,
                labelString: this.axis1Label,
              },
            }, {
              type: 'linear',
              display: true,
              position: 'right',
              id: 'y-axis-2',
              ticks: {
                callback: (value) => `${value} ${this.chartSymbolAxis2}`,
              },
              gridLines: {
                drawOnChartArea: false,
              },
              scaleLabel: {
                display: true,
                labelString: this.axis2Label,
              },
            }],
        },
      };
    },
  },
  async mounted() {
    if (Object.keys(this.telemetry).length > 0) {
      const telemetryTemp = this.getDataAndLabels(this.alarmSelected);
      this.basicData.labels = telemetryTemp.tempLabels;
      this.basicData.datasets = telemetryTemp.tempDataSets;
      if (this.alarmSelected) {
        await this.displaySelectedAlarm(telemetryTemp);
      }
    }
    this.loading = false;
  },
  watch: {
    async telemetry() {
      this.loading = true;
      const telemetryTemp = this.getDataAndLabels(this.alarmSelected);
      this.basicData.labels = telemetryTemp.tempLabels;
      this.basicData.datasets = telemetryTemp.tempDataSets;
      if (this.alarmSelected) {
        await this.displaySelectedAlarm(telemetryTemp);
      }
      if (this.$refs.chart) {
        this.$refs.chart.refresh();
      }
      this.loading = false;
    },
  },
  methods: {
    async displaySelectedAlarm(telemetryTemp) {
      let highestValue = null;
      let highestValueIndex = null;
      const alarmPinpointArray = [];
      const { threshold } = this.clickedAlarm.data;
      const output = telemetryTemp.tempTs.reduce((prev, curr) => (Math.abs(curr - this.clickedAlarm.data.ts) < Math.abs(prev - this.clickedAlarm.data.ts)
        ? curr
        : prev));
      highestValueIndex = telemetryTemp.tempTs.indexOf(output);
      highestValue = telemetryTemp.tempDataSets[0].data[highestValueIndex];
      alarmPinpointArray[highestValueIndex] = highestValue;
      this.basicData.datasets = telemetryTemp.tempDataSets;
      if (threshold) {
        const thresholdArray = [];
        for (let i = 0; i < telemetryTemp.tempTs.length; i++) {
          thresholdArray.push(threshold);
        }
        this.basicData.datasets.push(
          {
            label: 'Alarm threshold',
            data: thresholdArray,
            fill: false,
            borderColor: '#f39d9d',
          },
        );
      }
      this.basicData.datasets.push(
        {
          label: `Alarm at sensor ${this.clickedAlarm.data.originator}`,
          data: alarmPinpointArray,
          fill: false,
          borderColor: '#e6b6b0',
          pointStyle: 'triangle',
          pointRadius: 10,
          pointBackgroundColor: '#e6b6b0',
          pointBorderColor: '#e6b6b0',
        },
      );
      this.basicData.labels = telemetryTemp.tempLabels;
      const alarm = {
        originator: this.clickedAlarm.data.originator,
        value: highestValue,
      };

      await this.selectCurrentAlarm(alarm);
    },
    getDataAndLabels(isAlarm) {
      const tempLabels = [];
      let a = 0;
      let format = 'DD/MM/Y HH:mm';
      if (isAlarm) {
        format = 'DD/MM/Y HH:mm:ss';
      }
      const tempTs = [];
      Object.entries(this.telemetry).map(([, value]) => {
        if (typeof (value) === 'object') {
          value?.forEach((item) => {
            if (tempTs.indexOf(item.ts) === -1) {
              tempTs.push(item.ts);
            }
          });
        }
      });
      tempTs.sort((ts1, ts2) => ts1 - ts2);
      for (let i = 0; i < tempTs.length; i++) {
        if (tempLabels.indexOf(moment(tempTs[i]).format(format)) === -1) {
          tempLabels.push(moment(tempTs[i]).format(format));
        }
      }

      let tempSensorCount = 0;
      const tempDataSets = Object.entries(this.telemetry).map(([key, value]) => {
        const index = this.selectedDevice.sensors.findIndex((sensor) => sensor.name === key);
        let sensorType = '';
        const tempVals = [];
        value.map((item) => {
          const tempDate = moment(item.ts).format(format);
          const tempIndex = tempLabels.indexOf(tempDate);
          tempVals[tempIndex] = item.value;
        });
        const borderColor = this.hexColours[a] ? this.hexColours[a] : this.hexColours[Math.floor(Math.random() * this.hexColours.length)];
        a++;
        let axis = 'y-axis-1';
        if (index !== -1 && this.selectedDevice.sensors[index]) {
          sensorType = this.selectedDevice.sensors[index].type;
        }
        if (sensorType === 'temperature') {
          if (this.axis2Label !== 'Temperature' && this.axis1Label !== 'Temperature') {
            if (!this.secondAxisTaken) {
              axis = 'y-axis-2';
              tempSensorCount++;
              this.secondAxisTaken = true;
              this.chartSymbolAxis2 = '℃';
              this.axis2Label = 'Temperature';
            } else {
              this.chartSymbolAxis1 = '℃';
              this.axis1Label = 'Temperature';
            }
          }
        }
        if (sensorType === 'displacement' || sensorType === 'cracks') {
          if (!this.secondAxisTaken) {
            axis = 'y-axis-2';
            this.chartSymbolAxis2 = 'mm';
            this.secondAxisTaken = true;
            if (sensorType === 'cracks') {
              this.axis2Label = 'Cracks';
            } else {
              this.axis2Label = 'Displacement';
            }
          } else {
            this.chartSymbolAxis1 = 'mm';
            if (sensorType === 'cracks') {
              this.axis1Label = 'Cracks';
            } else {
              this.axis1Label = 'Displacement';
            }
          }
        }

        if (sensorType === 'potential') {
          if (!this.secondAxisTaken) {
            axis = 'y-axis-2';
            this.chartSymbolAxis2 = 'mV';
            this.axis2Label = 'Potential';
            this.secondAxisTaken = true;
          } else {
            this.chartSymbolAxis1 = 'mV';
            this.axis1Label = 'Potential';
          }
        }

        if (sensorType === 'current') {
          if (!this.secondAxisTaken) {
            axis = 'y-axis-2';
            this.chartSymbolAxis2 = 'mA';
            this.axis2Label = 'Current';
            this.secondAxisTaken = true;
          } else {
            this.chartSymbolAxis1 = 'mA';
            this.axis1Label = 'Current';
          }
        }

        if (sensorType && sensorType.includes('tilt')) {
          if (!this.secondAxisTaken) {
            axis = 'y-axis-2';
            this.chartSymbolAxis2 = 'mm/m';
            this.secondAxisTaken = true;
            this.axis2Label = 'Tilt';
          } else {
            this.chartSymbolAxis1 = 'mm/m';
            this.axis1Label = 'Tilt';
          }
        }

        return {
          label: key,
          data: tempVals,
          fill: false,
          borderColor,
          yAxisID: axis,
        };
      });

      this.options.scales.yAxes[1].display = tempSensorCount !== 0;

      return {
        tempDataSets,
        tempLabels,
        tempTs,
      };
    },
  },
};
</script>
