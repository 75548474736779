<template>
  <div class="row mx-5">
      <div class="col-12 mt-4 h-auto">
        <div class="w-100 m-0 px-4 py-4 mb-4 upload-wrapper">
          <h4 class="mr-1 text-left"> Admin panel </h4>
          <div class="large-12 medium-12 small-12 cell w-100">
            <div class="input-group ml-0 mt-3 mb-2 text-left w-100">
              <label for="companySelector">Select company to administer</label>
              <company-selector
                @company-changed="dropDownChangeCompany($event)"
                :loading="loading"
                :options="companies"
                :classProp="'w-100'"
                :initalCompany="selectedCompany">
              </company-selector>
              <div class="mt-2" v-if="!loading">
                <label for="device">Select device to administer</label>
                <device-selector
                  @device-changed="dropDownChangeDevice($event)"
                  :loading="loading"
                  :options="devices"
                  :initial-device="selectedDevice"
                  :classProp="'w-100'">
                </device-selector>
              </div>
            </div>
          </div>
        </div>

        <alarm-management-box
          :initial-device="selectedDevice"
          :initial-alarm="selectedAlarm"
          :options="selectedDevice?.alarms"
          @alarm-changed="resetInputs()"
        >
        </alarm-management-box>

        <div class="d-flex my-1 mt-4">
          <div class="mx-auto my-auto upload-wrapper px-4 py-4 text-left">
            <h4>Upload reports</h4>
            <div class="large-12 medium-12 small-12 cell">

              <div class="input-group ml-0 mt-3 mb-2 w-100">
                <label class="text-left text-muted" for="description">Description</label>
                <input-text
                  class="w-100"
                  id="description"
                  type="email"
                  v-model="reportDescription"
                  placeholder="Enter the report description here"
                  ref="description"
                />
              </div>

              <div class="input-group ml-0 mb-2 w-100">
                 <label class="text-left text-muted" for="file">File Upload</label>
                 <input id="file" type="file" class="file-input mw-100"
                        ref="file" @change="handleFileUpload()"/>
              </div>

              <pv-button class="mb-2 btn btn-black" @click="submitFile()" :disabled="loading">
                SUBMIT  <i v-if="loading" class='fa fa-circle-o-notch fa-spin'></i>
              </pv-button>

              <p class="small"> Only upload reports in PDF format</p>
            </div>
          </div>
        </div>
        <div v-if="selectedCompany" class="d-flex my-1 mt-4">
          <div class="mx-auto my-auto upload-wrapper px-4 py-4 text-left mh-100">

            <h4>Manage reports</h4>
            <div class="large-12 medium-12 small-12 cell">
              <data-table id="dt"
                          :value="selectedCompany.documents"
                          class="p-datatable-gridlines"
                          dataKey="id"
                          :scrollable="true"
                          scrollHeight="200px">
                <column class="wrap-bw" field="description" header="Description"></column>
                <column class="wrap-bw my-0 py-0" field="created_at" header="Date Uploaded">
                  <template #body="slotProps">
                    <p class="mb-0">
                      {{ getReadableDate(slotProps.data.created_at)}}
                    </p>
                    <i v-if="!loading" @click="deleteSelectedReport(slotProps.data.id)" class='ml-auto fa fa-times cursor-pointer'></i>
                    <i v-else class='ml-auto fa fa-circle-o-notch fa-spin'></i>
                  </template>
                </column>
                <template #empty>
                  No reports found.
                </template>
              </data-table>
            </div>

          </div>
        </div>

        <div class="d-flex my-1 my-4">
          <div class="mx-auto my-auto upload-wrapper px-4 py-4 text-left">
            <h4 class="ml-3">Upload device VTP</h4>
            <div class="large-12 medium-12 small-12 cell">

              <div class="col-12 w-100 input-group ml-0 mt-3 mb-2">
                <label class="text-muted" for="device">Select device</label>
                <device-selector @device-changed="dropDownChangeDevice($event)" :loading="loading" :options="devices" :initial-device="selectedDevice" :classProp="'w-100'" ></device-selector>
              </div>

              <div v-if="selectedDevice !== null && selectedDevice.model.findIndex(item => item.type === 'default' ) === -1" class="input-group col-12 mb-2 w-100">
                <label class="text-left text-muted" for="vtpFile">Vtp Upload</label>
                <input id="vtpFile" type="file" class="file-input w-100 mw-100"
                       ref="vtpFile" @change="handleVtpFileUpload()"/>
              </div>

              <div v-else-if="selectedDevice !== null" class="input-group col-12 mb-2 w-100">
                <p class="text-left text-muted">Current VTP:</p>
                <p class="mb-0">
                  {{ selectedDevice.model[selectedDevice.model.findIndex(item => item.type === 'default' )].file_path }}
                  <i v-if="!loading" @click="deleteSelectedVtp(selectedDevice.model[selectedDevice.model.findIndex(item => item.type === 'default' )].id)" class='ml-auto fa fa-times cursor-pointer'></i>
                </p>
              </div>

              <div v-if="selectedDevice !== null && selectedDevice.model.findIndex(item => item.type === 'full-sensor' ) === -1" class="input-group col-12 mb-2 w-100">
                <label class="text-left text-muted" for="fullSensorVtpFile">All Sensor Vtp Upload</label>
                <input id="fullSensorVtpFile" type="file" class="file-input w-100 mw-100"
                       ref="fullSensorVtpFile" @change="handleAllSensorVtpUpload()"/>
              </div>

              <div v-else-if="selectedDevice !== null" class="input-group col-12 mb-2 w-100">
                <p class="text-left text-muted">Current Full-Sensor VTP:</p>
                <p class="mb-0">
                  {{ selectedDevice.model[selectedDevice.model.findIndex(item => item.type === 'full-sensor' )].file_path }}
                  <i v-if="!loading" @click="deleteSelectedVtp(selectedDevice.model[selectedDevice.model.findIndex(item => item.type === 'full-sensor' )].id)" class='ml-auto fa fa-times cursor-pointer'></i>
                </p>
              </div>

              <pv-button class="mb-2 ml-3 btn btn-black" @click="submitVtpFiles()" :disabled="loading">
                SUBMIT  <i v-if="loading" class='fa fa-circle-o-notch fa-spin'></i>
              </pv-button>
            </div>
          </div>
        </div>

        <div class="d-flex my-1 my-4">
          <div class="mx-auto my-auto upload-wrapper px-4 py-4 text-left">
            <h4 class="ml-3">Update sensor locations</h4>
            <p class="ml-3 text-muted">Add sensor location in x,y,z format in order to show it on the 3D model. Region ID is reserved for All Sensor VTPs with predefined regions.</p>
            <div class="large-12 medium-12 small-12 cell">

              <div class="col-12 w-100 input-group ml-0 mt-3 mb-2">
                 <label class="text-muted" for="device">Select device</label>
                 <device-selector @device-changed="dropDownChangeDevice($event)" :loading="loading" :options="devices" :initial-device="selectedDevice" :classProp="'w-100'" ></device-selector>
               </div>

              <div v-if="selectedDevice" class="p-inputgroup input-group col-12 mb-2 w-100">
                <div class="row m-0" v-bind:key="sensor.id" v-for="sensor in selectedDevice.sensors">
                  <input-text
                  class="col-5 mr-4"
                  type="text"
                  :value="sensor.name"
                  :placeholder="sensor.name"
                  ref="description"
                  disabled
                  />

                  <input-text
                  class="col-5"
                  :id="'sensor-location-' + sensor.id"
                  type="text"
                  v-model="sensor.model_location"
                  placeholder="Location (x,y,z)"
                  title="Sensor location needs to be in the x,y,z format (ex. 1200,1500,2000)"
                  />

                  <input-text
                      class="col-2 ml-4"
                      :id="'sensor-region-' + sensor.id"
                      type="text"
                      v-model="sensor.region_id"
                      placeholder="Region"
                  />
                   <div class="col-12 row m-0">
                     <p class="col-5"></p>
                     <small
                         :id="'sensor-location-' + sensor.id + '-help'"
                         class="p-error d-none px-0 col-5"
                     >
                       Sensor location needs to be in the x,y,z format (ex. 1200,1500,2000)
                     </small>
                   </div>
                </div>
              </div>

              <pv-button class="mb-2 ml-3 btn btn-black" @click="submitSensorLocationsAndRegions()" :disabled="loading">
                SUBMIT  <i v-if="loading" class='fa fa-circle-o-notch fa-spin'></i>
              </pv-button>

            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import moment from 'moment';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useToast } from 'vue-toastification';
import router from '../router';
import CompanySelector from '../components/CompanySelector.vue';
import DeviceSelector from '../components/DeviceSelector.vue';
import AlarmManagementBox from '../components/AlarmManagementBox.vue';

const toast = useToast();

export default {
  components: {
    CompanySelector, DeviceSelector, AlarmManagementBox,
  },
  setup() {
    const store = useStore();

    function loading() {
      return store.state.users.companiesLoading
          || store.state.alarms.alarmsLoading
          || store.state.files.filesLoading
          || store.state.devices.devicesDataLoading;
    }

    return {
      userRole: computed(() => store.state.users.userRole),
      updateSensorLocationsRegions: (formData) => store.dispatch('devices/updateSensorLocationsRegions', formData),
      uploadReport: (formData) => store.dispatch('files/uploadReport', formData),
      uploadVtp: (formData) => store.dispatch('files/uploadVtp', formData),
      deleteReport: (reportId) => store.dispatch('files/deleteReport', reportId),
      deleteVtp: (vtpId) => store.dispatch('files/deleteVtp', vtpId),
      loadCompanies: () => store.dispatch('users/getCompanies'),
      setDataLoading: (dataLoading) => store.commit('devices/setDevicesDataLoading', dataLoading),
      loading: computed(loading),
      companies: computed(() => store.state.users.companies),
    };
  },
  data() {
    return {
      devices: null,
      alarms: null,
      reportDescription: null,
      alarmStatuses: [
        { status: 'ACTIVE_ACK', label: 'In Progress / Acknowledged' },
        { status: 'CLEARED_ACK', label: 'Cleared' },
      ],
      selectedCompany: null,
      selectedAlarm: null,
      selectedDevice: null,
      isCompanySelected: false,
      fullSensorVtpFile: false,
      file: null,
      vtpFile: null,
    };
  },
  async mounted() {
    if (this.userRole !== 'admin') {
      await router.push({ name: 'Home' });
    } else {
      await this.loadCompanies();
      [this.selectedCompany] = this.companies;
      this.devices = this.selectedCompany.devices;
      [this.selectedDevice] = this.devices;
      // this.alarms = this.getActiveAlarms(this.selectedDevice.alarms);
      this.alarms = this.selectedDevice.alarms;
      if (this.alarms?.length > 0) {
        [this.selectedAlarm] = this.alarms;
      }
      this.setDataLoading(false);
      this.isCompanySelected = true;
    }
  },
  methods: {
    async resetInputs() {
      this.vtpFile = null;
      this.file = null;
      this.$refs.file.value = null;
      this.reportDescription = null;
      this.fullSensorVtpFile = null;
      this.selectedAlarm = null;
      this.selectedDevice = null;
      await this.loadCompanies();
      [this.selectedCompany] = this.companies;
      this.devices = this.selectedCompany.devices;
      [this.selectedDevice] = this.devices;
      // this.alarms = this.getActiveAlarms(this.selectedDevice.alarms);
      this.alarms = this.selectedDevice.alarms;
      if (this.alarms.length > 0) {
        [this.selectedAlarm] = this.alarms;
      }
      this.isCompanySelected = true;
    },
    async deleteSelectedReport(id) {
      await this.deleteReport(id);
      await this.resetInputs();
    },
    async deleteSelectedVtp(id) {
      await this.deleteVtp(id);
      await this.resetInputs();
    },
    // Not used atm but would be once there is alarms that need to be on Thingsboard exclusively
    getActiveAlarms(alarms) {
      return alarms.filter((alarm) => alarm.status !== 'CLEARED_ACK');
    },
    dropDownChangeDevice($event) {
      this.selectedDevice = $event;
      this.alarms = this.selectedDevice.alarms;
      if (this.alarms?.length > 0) {
        [this.selectedAlarm] = this.alarms;
      } else {
        this.selectedAlarm = null;
      }
      this.isCompanySelected = true;
    },
    async dropDownChangeCompany($event) {
      this.selectedCompany = $event.value;
      this.devices = this.selectedCompany.devices;
      if (this.devices?.length > 0) {
        [this.selectedDevice] = this.devices;
      } else {
        this.selectedDevice = null;
      }
      if (this.selectedDevice) {
        // this.alarms = await this.getActiveAlarms(this.selectedDevice.alarms);
        this.alarms = this.selectedDevice.alarms;
      } else {
        this.alarms = null;
      }
      if (this.alarms?.length > 0) {
        [this.selectedAlarm] = this.alarms;
      } else {
        this.selectedAlarm = null;
      }
      this.isCompanySelected = true;
    },
    handleFileUpload() {
      const tempFile = this.$refs.file.files[0];
      if (tempFile.type === 'application/pdf') {
        this.file = tempFile;
      } else {
        this.$refs.file.value = null;
        toast.error('You can only upload PDF documents');
      }
    },
    handleVtpFileUpload() {
      const tempFile = this.$refs.vtpFile.files[0];
      if (tempFile.name.slice(-3) === 'vtp') {
        this.vtpFile = tempFile;
      } else {
        this.$refs.vtpFile.value = null;
        toast.error('You can only upload VTP documents');
      }
    },
    handleAllSensorVtpUpload() {
      const tempFile = this.$refs.fullSensorVtpFile.files[0];
      if (tempFile.name.slice(-3) === 'vtp') {
        this.fullSensorVtpFile = tempFile;
      } else {
        this.$refs.fullSensorVtpFile.value = null;
        toast.error('You can only upload VTP documents');
      }
    },
    getReadableDate(value) {
      if (value.timestamp) {
        return moment(value.timestamp).format('DD/MM/YY HH:mm');
      }
      return moment(value).format('DD/MM/YY HH:mm');
    },
    async submitFile() {
      const description = document.getElementById('description').value;
      if (description) {
        const companyId = this.selectedCompany.id;
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('description', description);
        formData.append('company_id', companyId);
        await this.uploadReport(formData);
        await this.resetInputs();
        if (document.getElementById('description').className.indexOf('p-invalid') === -1) {
          document.getElementById('description').className.replace('p-invalid', '');
        }
      } else if (document.getElementById('description').className.indexOf('p-invalid') === -1) {
        document.getElementById('description').classList.add('p-invalid');
      }
    },
    async submitSensorLocationsAndRegions() {
      const formData = new FormData();
      const sensorLocationsRegions = [];
      const regexSensorLocation = /[+-]?[0-9]{1,10},[+-]?[0-9]{1,10},[+-]?[0-9]{1,10}/;
      const regexRegion = /[0-9]/;
      let availableToSubmit = true;
      for (let i = 0; i < this.selectedDevice.sensors.length; i++) {
        const locationInputId = `sensor-location-${this.selectedDevice.sensors[i].id}`;
        const sensorLocation = document.getElementById(locationInputId).value;

        if (sensorLocation && !regexSensorLocation.test(sensorLocation)) {
          document.getElementById(locationInputId).classList.add('p-invalid');
          document.getElementById(`${locationInputId}-help`).classList.remove('d-none');
          availableToSubmit = false;
        } else if (document.getElementById(locationInputId).classList.contains('p-invalid')) {
          document.getElementById(locationInputId).classList.remove('p-invalid');
          document.getElementById(`${locationInputId}-help`).classList.add('d-none');
        } else if (!document.getElementById(`${locationInputId}-help`).classList.contains('d-none')) {
          document.getElementById(`${locationInputId}-help`).classList.add('d-none');
        }

        const regionInputId = `sensor-region-${this.selectedDevice.sensors[i].id}`;
        const regionId = document.getElementById(regionInputId).value;
        if (regionId && !regexRegion.test(regionId)) {
          document.getElementById(regionInputId).classList.add('p-invalid');
          availableToSubmit = false;
        } else if (!document.getElementById(regionInputId).classList.contains('p-invalid')) {
          document.getElementById(regionInputId).classList.remove('p-invalid');
        }
        const sensorData = {
          sensorId: this.selectedDevice.sensors[i].id,
          model_location: sensorLocation,
          region_id: regionId,
        };
        sensorLocationsRegions.push(JSON.stringify(sensorData));
      }
      if (availableToSubmit) {
        formData.append('sensorData', sensorLocationsRegions);
        formData.append('deviceId', this.selectedDevice.id);
        await this.updateSensorLocationsRegions(formData);
        await this.resetInputs();
      } else {
        toast.error('The highlighted inputs are invalid');
      }
    },
    async submitVtpFiles() {
      const deviceId = this.selectedDevice.id;
      const formData = new FormData();
      if (this.vtpFile) {
        formData.append('vtpFile', this.vtpFile);
      }
      if (this.fullSensorVtpFile) {
        formData.append('fullSensorVtpFile', this.fullSensorVtpFile);
      }
      formData.append('deviceId', deviceId);
      await this.uploadVtp(formData);
      await this.resetInputs();
    },
  },
};
</script>
