import { createApp } from 'vue';
import axios from 'axios';
import Toast from 'vue-toastification';
import moment from 'moment';
import VueHtml2Canvas from 'vue-html2canvas';
import 'vue-toastification/dist/index.css';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import OverlayPanel from 'primevue/overlaypanel';
import Tag from 'primevue/tag';
import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Listbox from 'primevue/listbox';
import DataView from 'primevue/dataview';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import Accordion from 'primevue/accordion';
import Carousel from 'primevue/carousel';
import AccordionTab from 'primevue/accordiontab';
import FileUpload from 'primevue/fileupload';
import store from './state';
import App from './App.vue';
import router from './router';

require('primevue/resources/themes/saga-blue/theme.css'); // theme
require('primevue/resources/primevue.min.css'); // core css
// import '../node_modules/primeicons/primeicons.css';
require('./sass/styles.sass');
require('./sass/font-awesome.min.sass');
require('bootstrap/dist/css/bootstrap.css');
// import 'bootstrap-vue/dist/bootstrap-vue.css'

const apiPrefix = process.env.VUE_APP_API_PREFIX
  ? process.env.VUE_APP_API_PREFIX
  : 'http://localhost:8000/api';

axios.defaults.baseURL = apiPrefix;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('users/logout');
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

const app = createApp(App);

app.use(store)
  .use(router)
  .use(PrimeVue)
  .use(Toast)
  .use(VueHtml2Canvas)
  .use(moment)
  .component('column', Column)
  .component('input-switch', InputSwitch)
  .component('data-table', DataTable)
  .component('radio-button', RadioButton)
  .component('password', Password)
  .component('data-view', DataView)
  .component('calendar', Calendar)
  .component('pv-button', Button)
  .component('list-box', Listbox)
  .component('input-text', InputText)
  .component('dropdown', Dropdown)
  .component('file-upload', FileUpload)
  .component('overlay-panel', OverlayPanel)
  .component('carousel', Carousel)
  .directive('tooltip', Tooltip)
  .component('chart', Chart)
  .component('tag', Tag)
  .component('accordion', Accordion)
  .component('accordion-tab', AccordionTab);

app.mount('#app');
